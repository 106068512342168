import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import {
  TactilePage,
  TactilePageIndex,
  TactilePagePreview,
  TactileTableResponse,
} from './types';

const ACCEPT = 'application/json';
const CONTENT_TYPE = 'application/json; charset=utf-8';

export async function fetchAdminPagesIndex(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTableResponse<TactilePageIndex>> {
  const result = await fetchMedia(`${endpoint}/company/page/table`, {
    headers: {
      accept: ACCEPT,
      authorization,
      contentType: CONTENT_TYPE,
    },
    body: {
      search: { term: '' },
      find: {},
      populate: [],
      sort: {},
      select: {
        eventRef: 1,
        locationRef: 1,
        name: 1,
        _v: 1,
        _id: 1,
        subTitle: 1,
      },
      page: { items: 1000, current: 0 },
    },
    method: 'POST',
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactileTableResponse<TactilePageIndex>>(result);
}

export async function fetchAdminPage(
  pageId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  if (!pageId) {
    throw new ApiClientError('Missing page id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const url = `${endpoint}/company/page/${pageId}/get`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

/**
 * @deprecated use pathEditorPage
 */
export async function patchPage(
  pageId: string,
  endpoint: string,
  authorization: string,
  page: { page: Partial<Pick<TactilePage, 'content' | 'name' | '_v'>> },
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  if (!pageId) {
    throw new ApiClientError('Missing page id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const url = `${endpoint}/company/page/${pageId}/update`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
      contentType: CONTENT_TYPE,
    },
    method: 'PATCH',
    body: page,
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

/**
 * @deprecated use createEditorPage
 */
export async function initializePage(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  const url = `${endpoint}/company/page/create`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    method: 'GET',
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

export async function createEditorPage(
  endpoint: string,
  authorization: string,
  page: {
    page: Partial<Pick<TactilePage, 'content' | 'name' | '_v' | 'subTitle'>>;
  },
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  const url = `${endpoint}/application/editor/page/`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
      contentType: CONTENT_TYPE,
    },
    method: 'POST',
    body: page,
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

export async function patchEditorPage(
  pageId: string,
  endpoint: string,
  authorization: string,
  page: { page: Partial<Pick<TactilePage, 'content' | 'name' | '_v'>> },
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  if (!pageId) {
    throw new ApiClientError('Missing page id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const url = `${endpoint}/application/editor/page/${pageId}`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
      contentType: CONTENT_TYPE,
    },
    method: 'PATCH',
    body: page,
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

export async function fetchEditorPage(
  pageId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  if (!pageId) {
    throw new ApiClientError('Missing page id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const url = `${endpoint}/application/editor/page/${pageId}`;

  const result = await fetchMedia(url, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<TactilePage>(result);
}

export async function fetchEditorPages(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactilePagePreview[]> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/editor/page`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchApplicationPage(
  pageId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePage> {
  if (!pageId) {
    throw new ApiClientError('Missing page id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/page/${pageId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}
