import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DataTable, Surface, useTheme, Text } from 'react-native-paper';
export function Table({ round, headers, values, columns, renderFallback, }) {
    const { roundness } = useTheme();
    const iterator = useMemo(() => {
        const result = [];
        for (let i = 0; i < columns; i++) {
            result.push(i);
        }
        return result;
    }, [columns]);
    if (values.length === 0 || columns === 0) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const topBorderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        overflow: 'hidden',
    };
    const bottomBorderStyle = {
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [topBorderStyle, bottomBorderStyle, styles.surface] },
        React.createElement(DataTable, { style: [
                topBorderStyle,
                bottomBorderStyle,
                {
                    overflow: 'hidden',
                },
            ] },
            React.createElement(DataTable.Header, { style: [topBorderStyle, { overflow: 'hidden' }] }, iterator.map((i) => (React.createElement(DataTable.Title, { numeric: i > 0, key: i, style: { flex: i === 0 ? 3 : 1 } }, headers[i] || '')))),
            values.map((row, index, self) => (React.createElement(DataTable.Row, { key: index, style: index === self.length - 1
                    ? [
                        bottomBorderStyle,
                        {
                            overflow: 'hidden',
                        },
                    ]
                    : undefined }, iterator.map((i) => (React.createElement(Text, { key: i, style: { flex: i === 0 ? 3 : 1 } }, row[i])))))))));
}
// TODO: figure out what numeric={i > 0} contributed to the style
const styles = StyleSheet.create({
    surface: { elevation: 1 },
});
