import { fetchRegisteredTimeslot } from '@introcloud/api-client';
import { useBlockDataTimeslots, useBlockNavigation, useBlockSettings, } from '@introcloud/blocks-interface';
import React, { Fragment, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Caption, Divider, List, Text, } from 'react-native-paper';
import { localeLongWeekDayString, localeTimeString, } from 'react-native-time-helpers';
import { EventIcon } from './EventIcon';
import { EventTag } from './EventTag';
import { localeSmartTimeString } from './utils';
export function EventListItemDescriptionFocus({ id, title, description, duration, tags, illustration, isLast, hasTimeslots, loading, }) {
    const { EventListItem: { lines, imageEnabled, tagsEnabled }, } = useBlockSettings();
    const { gotoEvent } = useBlockNavigation();
    const renderLeft = useCallback((props) => (React.createElement(EventIcon, Object.assign({ loading: loading, tags: tags, illustration: illustration, fallback: !tagsEnabled }, props))), [illustration, loading, tags]);
    const renderDescription = useCallback(() => (React.createElement(View, { style: styles.descriptionWrapper },
        React.createElement(Caption, { numberOfLines: lines || 3, style: styles.description }, (description || '').replace(/\n\n+/g, '\n')),
        React.createElement(View, { style: styles.tags },
            tagsEnabled && React.createElement(EventTags, { tags: tags }),
            React.createElement(View, { style: styles.timeWrapper }, hasTimeslots ? (React.createElement(TimeslotTime, { fallbackStart: duration.start, fallbackEnd: duration.end, id: id })) : (React.createElement(EventTime, { start: duration.start, end: duration.end })))),
        !imageEnabled && React.createElement(LoadingIndicator, { active: loading, id: id }))), [
        lines,
        description,
        duration.start,
        duration.end,
        loading,
        id,
        tagsEnabled,
        imageEnabled,
    ]);
    return (React.createElement(Fragment, null,
        React.createElement(List.Item, { style: styles.item, titleStyle: styles.title, title: title, left: imageEnabled ? renderLeft : undefined, onPress: () => gotoEvent(id), description: renderDescription }),
        !isLast && React.createElement(Divider, { style: { height: StyleSheet.hairlineWidth } })));
}
function LoadingIndicator({ id, active }) {
    if (!active) {
        return null;
    }
    return (React.createElement(Fragment, null,
        React.createElement(ActivityIndicator, { style: styles.loading }),
        __DEV__ ? (React.createElement(Caption, { style: {
                position: 'absolute',
                bottom: 0,
                left: 0,
            } }, id)) : null));
}
export function TimeslotTime({ fallbackStart, fallbackEnd, id, }) {
    const { data, error } = useBlockDataTimeslots(id);
    const timeslot = (data === null || data === void 0 ? void 0 : data.timeslots)
        ? fetchRegisteredTimeslot(data === null || data === void 0 ? void 0 : data.timeslots)
        : undefined;
    if (error || !timeslot) {
        return React.createElement(EventTime, { start: fallbackStart, end: fallbackEnd });
    }
    return React.createElement(EventTime, { start: timeslot.start.unix, end: timeslot.end.unix });
}
export function EventTime({ start, end }) {
    const startDate = useMemo(() => new Date(start || 0), [start]);
    const endDate = useMemo(() => new Date(end || 0), [end]);
    if (!start) {
        return null;
    }
    const startDay = localeLongWeekDayString(startDate);
    const endDay = localeLongWeekDayString(endDate);
    const startTime = localeTimeString(startDate, false);
    const endTime = localeTimeString(endDate, false);
    if (startDay === endDay || !end) {
        if (startTime === endTime || !end) {
            return (React.createElement(Text, null, localeSmartTimeString(startDate, new Date().getTime(), undefined, true)));
        }
        return (React.createElement(Text, null,
            localeSmartTimeString(startDate, new Date().getTime(), undefined, true),
            ' ',
            "- ",
            endTime));
    }
    return (React.createElement(Text, null,
        localeSmartTimeString(startDate, new Date().getTime()),
        " -",
        ' ',
        localeSmartTimeString(endDate, new Date().getTime(), startDate)));
}
function EventTags({ tags }) {
    if (!tags || tags.length === 0) {
        return null;
    }
    return (React.createElement(View, { style: { flexDirection: 'row', flexWrap: 'wrap', maxWidth: '100%' } }, tags.map((tag) => (React.createElement(EventTag, { key: tag, tag: tag })))));
}
const styles = StyleSheet.create({
    item: { marginVertical: 0, margin: 0 },
    title: {
        marginTop: 2,
        height: 24,
        lineHeight: 24,
        marginBottom: 0,
    },
    descriptionWrapper: { marginBottom: 0, position: 'relative' },
    description: { marginRight: 8 },
    tags: { flexDirection: 'row', flexWrap: 'wrap', minHeight: 40 },
    timeWrapper: {
        marginTop: 6,
        marginLeft: 'auto',
        height: 32,
        marginRight: 12,
        justifyContent: 'center',
    },
    loading: {
        position: 'absolute',
        top: 0,
        bottom: 18,
        left: 0,
        right: 0,
    },
});
