import { eventHasTimeslots, fetchRegisteredTimeslot, } from '@introcloud/api-client';
import { CardTimeProgressBar } from '@introcloud/blocks';
import { useBlockDataTimeslots } from '@introcloud/blocks-interface';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
export function TimeSpanBlock(block) {
    const { pageEvent } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { from, to } = block.value || {};
    const { mode } = block.options || {};
    if (!pageEvent || (mode === 'manual' && (!from || !to))) {
        return renderFallback();
    }
    const hasTimeslots = eventHasTimeslots(pageEvent);
    return mode === 'manual'
        ? createElement(TimeSpanManualBlock, { from: from, to: to })
        : createElement(hasTimeslots ? TimeSpanTimeslotBlock : TimeSpanEventBlock, {
            event: pageEvent,
        });
}
function TimeSpanManualBlock({ from, to }) {
    return createElement(CardTimeProgressBar, { from, to });
}
function TimeSpanTimeslotBlock({ event }) {
    const { data: timeslots } = useBlockDataTimeslots(event._id);
    const timeslot = timeslots
        ? fetchRegisteredTimeslot(timeslots.timeslots)
        : undefined;
    if (!timeslot) {
        return createElement(TimeSpanEventBlock, { event });
    }
    const { start: { unix: from }, end: { unix: to }, } = timeslot;
    return TimeSpanManualBlock({ from, to });
}
function TimeSpanEventBlock({ event }) {
    const { start: { unix: from }, end: { unix: to }, } = event.duration;
    return TimeSpanManualBlock({ from, to });
}
