import { useBlockNavigation } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Divider, IconButton, Menu } from 'react-native-paper';
import { PrimaryButton } from './Button';
import { mergeTranslations } from './utils';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                walking: 'Walking',
                biking: 'Biking',
                transit: 'Public Transit',
                maps: 'See in maps',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                walking: 'Lopen',
                biking: 'Fietsen',
                transit: 'Openbaar vervoer',
                maps: 'Bekijk in maps',
            },
        },
    },
});
export function DirectionsMenu({ label, icon, location, style, }) {
    const { gotoDirections } = useBlockNavigation();
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((prev) => !prev), [
        setActive,
    ]);
    const gotoBike = useCallback(() => gotoDirections(location, 'bicycling'), [
        gotoDirections,
        location,
    ]);
    const gotoTransit = useCallback(() => gotoDirections(location, 'transit'), [
        gotoDirections,
        location,
    ]);
    const gotoWalk = useCallback(() => gotoDirections(location, 'walking'), [
        gotoDirections,
        location,
    ]);
    const openInMaps = useCallback(() => gotoDirections(location, 'none'), [
        gotoDirections,
        location,
    ]);
    return (React.createElement(Menu, { anchor: label || !icon ? (React.createElement(PrimaryButton, { icon: icon, onPress: toggleActive, style: style }, label)) : (React.createElement(IconButton, { style: { marginRight: 16 }, icon: icon, onPress: toggleActive, accessibilityLabel: t('blocks.actions.directions') })), visible: active, onDismiss: toggleActive },
        React.createElement(Menu.Item, { onPress: gotoWalk, title: t('blocks.actions.walking'), icon: "walk" }),
        React.createElement(Menu.Item, { onPress: gotoBike, title: t('blocks.actions.biking'), icon: "bike" }),
        React.createElement(Menu.Item, { onPress: gotoTransit, title: t('blocks.actions.transit'), icon: "subway-variant" }),
        React.createElement(Divider, { style: { height: StyleSheet.hairlineWidth } }),
        React.createElement(Menu.Item, { onPress: openInMaps, title: t('blocks.actions.maps'), icon: "map-marker" })));
}
