import { KIND_ACTION, KIND_ACTION_LIST, KIND_CHAT, KIND_CONTACT, KIND_EVENT, KIND_EVENTS, KIND_TABLE, KIND_TEXT, KIND_IMAGE, KIND_VIDEO_EMBED, KIND_DIVIDER, KIND_LOCATIONS, KIND_TIME_SLOTS, KIND_TIME_SPAN, KIND_AUTO, KIND_VIDEO_STREAM, KIND_INTERESTED, KIND_CHECK_IN, KIND_SPONSOR, } from '../Kinds';
export function hasSurfacePadding(block_) {
    // Omit removes the auto type narrowing for the switch
    const block = block_;
    if (!(block === null || block === void 0 ? void 0 : block.kind)) {
        return false;
    }
    switch (block.kind) {
        case KIND_ACTION: {
            return true;
        }
        case KIND_AUTO: {
            return ['title', 'description'].includes(block.value.auto);
        }
        case KIND_CHAT: {
            return true;
        }
        case KIND_CONTACT: {
            return true;
        }
        case KIND_TEXT: {
            return true;
        }
        default: {
            return false;
        }
    }
}
const AUTO_SURFACE_AT_TOP = [
    'title',
    'description',
    'timespan',
];
const AUTO_SURFACE_AT_BOTTOM = [
    'title',
    'description',
    'sub-events',
    'locations',
    'upcoming-events',
];
export function hasSurfaceAtTop(block_) {
    var _a;
    if (!block_) {
        return false;
    }
    // Omit removes the auto type narrowing for the switch
    const block = block_;
    switch (block.kind) {
        case KIND_ACTION: {
            return true;
        }
        case KIND_ACTION_LIST: {
            return block.options.mode === 'list' && !block.value.label;
        }
        case KIND_AUTO: {
            if (AUTO_SURFACE_AT_TOP.includes(block.value.auto)) {
                return true;
            }
            if (['sub-events', 'locations', 'upcoming-events'].includes(block.value.auto)) {
                return !!block.value.label;
            }
            return false;
        }
        case KIND_CHAT: {
            return true;
        }
        case KIND_CHECK_IN: {
            return true;
        }
        case KIND_CONTACT: {
            return true;
        }
        case KIND_EVENT: {
            return true;
        }
        case KIND_EVENTS: {
            return !block.value.label;
        }
        case KIND_INTERESTED: {
            return true;
        }
        case KIND_LOCATIONS: {
            return !block.value.label;
        }
        case KIND_TABLE: {
            return true;
        }
        case KIND_TIME_SLOTS: {
            return !block.value.label;
        }
        case KIND_TIME_SPAN: {
            return true;
        }
        case KIND_TEXT: {
            return true;
        }
        case KIND_VIDEO_STREAM: {
            return !!((_a = block.options) === null || _a === void 0 ? void 0 : _a.surface);
        }
        default: {
            return false;
        }
    }
}
export function hasSurfaceAtBottom(block_) {
    var _a;
    if (!block_) {
        return false;
    }
    // Omit removes the auto type narrowing for the switch
    const block = block_;
    switch (block.kind) {
        case KIND_ACTION: {
            return true;
        }
        case KIND_ACTION_LIST: {
            return block.options.mode === 'list';
        }
        case KIND_AUTO: {
            if (AUTO_SURFACE_AT_BOTTOM.includes(block.value.auto)) {
                return true;
            }
            return false;
        }
        case KIND_CHAT: {
            return true;
        }
        case KIND_CHECK_IN: {
            return true;
        }
        case KIND_CONTACT: {
            return true;
        }
        case KIND_EVENT: {
            return true;
        }
        case KIND_EVENTS: {
            return true;
        }
        case KIND_INTERESTED: {
            return true;
        }
        case KIND_LOCATIONS: {
            return true;
        }
        case KIND_TABLE: {
            return true;
        }
        case KIND_TIME_SLOTS: {
            return true;
        }
        case KIND_TEXT: {
            return true;
        }
        case KIND_VIDEO_STREAM: {
            return !!((_a = block.options) === null || _a === void 0 ? void 0 : _a.surface);
        }
        default: {
            return false;
        }
    }
}
export function extendsFullyToTop(block) {
    if (!block) {
        return false;
    }
    if (hasSurfaceAtTop(block)) {
        return true;
    }
    switch (block.kind) {
        case KIND_IMAGE: {
            return true;
        }
        case KIND_DIVIDER: {
            return true;
        }
        case KIND_SPONSOR: {
            return true;
        }
        case KIND_VIDEO_EMBED: {
            return true;
        }
    }
    return false;
}
export function extendsFullyToBottom(block) {
    if (!block) {
        return true;
    }
    if (hasSurfaceAtBottom(block)) {
        return true;
    }
    switch (block.kind) {
        case KIND_IMAGE: {
            return true;
        }
        case KIND_DIVIDER: {
            return true;
        }
        case KIND_SPONSOR: {
            return true;
        }
        case KIND_VIDEO_EMBED: {
            return true;
        }
    }
    return false;
}
