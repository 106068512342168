import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactileSponsor } from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationSponsors(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactileSponsor[]> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/sponsor`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchApplicationSponsor(
  sponsorId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileSponsor> {
  if (!sponsorId) {
    throw new ApiClientError('Missing sponsor id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/sponsor/${sponsorId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}
