import { __rest } from "tslib";
import { AccentButton, PrimaryButton, TextButton, } from '@introcloud/blocks';
import { useBlockDataCheckinStatus, useBlockDataEvent, } from '@introcloud/blocks-interface';
import { mergeTranslations } from '@introcloud/blocks/dist/utils';
import { t } from 'i18n-js';
import { createElement, memo, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Caption, Surface, useTheme } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
import { useTranslation } from '../utils/useTranslation';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                interested: "I'm interested",
            },
            interested: {
                gdpr: {
                    email: 'Your contact information is NOT shared. The e-mail will be sent from our system.',
                    export: 'This gives consent to share your contact information.',
                },
                call_to_action: 'Press the button to indicate you {{reason}}. {{legal}}',
                default_reason: 'are interested',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                interested: 'Ik heb interesse',
            },
            interested: {
                gdpr: {
                    email: 'Jouw gegevens worden niet gedeeld. De e-mail wordt verstuurd vanaf ons systeem.',
                    export: 'Dit geeft toestemming om jouw gegevens te delen.',
                },
                call_to_action: 'Klik op de knop om aan te geven dat je {{reason}}. {{legal}}',
                default_reason: 'geïnteresseerd bent',
            },
        },
    },
});
export function InterestedBlock(block) {
    const { data, change, error, loading, refreshing } = useBlockDataCheckinStatus(block.value.event);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { roundness } = useTheme();
    const label = useTranslation(block.value.localizedLabel, block.value.label, t('blocks.actions.interested'));
    const reason = useTranslation(block.value.localizedReason, block.value.reason, t('blocks.interested.default_reason'));
    if (!block.value.event) {
        return renderFallback();
    }
    if (error && !data) {
        return renderFallback();
    }
    const { value: { icon, gdpr }, options: { spacing, round, mode }, next, previous, dragging, } = block;
    const roundTop = round && !extendsFullyToBottom(previous);
    const roundBottom = round && !extendsFullyToTop(next);
    const topRadius = roundTop ? roundness : 0;
    const bottomRadius = roundBottom ? roundness : 0;
    const shouldCollapseMarginTop = !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const fixedSpace = spacing === undefined ? 2 : spacing;
    const style = fixedSpace
        ? {
            paddingTop: shouldCollapseMarginTop ? 0 : fixedSpace * 8,
            paddingBottom: fixedSpace * 8,
        }
        : undefined;
    const active = !!(data && data.id !== 'leave');
    const gdprLegal = gdpr === 'email'
        ? t('blocks.interested.gdpr.email')
        : t('blocks.interested.gdpr.export');
    const legal = active
        ? gdprLegal
        : t('blocks.interested.call_to_action', { reason, legal: gdprLegal });
    return createElement(Surface, {
        style: [
            style,
            {
                elevation: 1,
                borderTopLeftRadius: topRadius,
                borderTopRightRadius: topRadius,
                borderBottomLeftRadius: bottomRadius,
                borderBottomRightRadius: bottomRadius,
            },
        ],
        children: [
            createElement(View, {
                key: 'button-wrapper',
                style: {
                    marginLeft: 16,
                    marginRight: 'auto',
                },
            }, createElement(EventInterestedButton, {
                disabled: loading || refreshing,
                active,
                change,
                data,
                label,
                icon: active ? (icon === null || icon === void 0 ? void 0 : icon.yes) || 'heart' : (icon === null || icon === void 0 ? void 0 : icon.no) || 'heart-outline',
                loading: loading || refreshing,
                mode,
                surface: false,
                inset: true,
                eventId: block.value.event,
            })),
            createElement(Caption, {
                key: 'caption',
                style: {
                    paddingLeft: 16,
                    paddingRight: 16,
                    width: '100%',
                    marginTop: 8,
                },
                children: legal,
            }),
        ],
    });
}
function EventInterestedButton(_a) {
    var { eventId, disabled, label, active, change, data } = _a, props = __rest(_a, ["eventId", "disabled", "label", "active", "change", "data"]);
    const { data: event, loading } = useBlockDataEvent(eventId);
    // Disable check because the module says it's disabled
    const disableCheckIn = useMemo(() => {
        var _a;
        if (!event || !((_a = event.module) === null || _a === void 0 ? void 0 : _a.application)) {
            return false;
        }
        if (active) {
            return true;
        }
        return !event.module.application.canCheckIn;
    }, [event, active]);
    const onPress = useCallback(() => {
        if (active) {
            return;
        }
        change('go');
    }, [change, active]);
    return createElement(InterestedButton, Object.assign(Object.assign({}, props), { onPress, key: 'button', disabled: disabled || loading || disableCheckIn, label }));
}
function InterestedButton_(_a) {
    var { mode, label, onPress } = _a, other = __rest(_a, ["mode", "label", "onPress"]);
    switch (mode) {
        case 'primary': {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress, children: label }));
        }
        case 'accent': {
            return createElement(AccentButton, Object.assign(Object.assign({}, other), { onPress, children: label }));
        }
        case 'text': {
            return createElement(TextButton, Object.assign(Object.assign({}, other), { onPress, children: label }));
        }
        default: {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress, children: label }));
        }
    }
}
const InterestedButton = memo(InterestedButton_);
