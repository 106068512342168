import { __rest } from "tslib";
import { DirectionsMenu } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { View } from 'react-native';
import { Surface } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
import { hasSurfacePadding } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
export function DirectionsBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!block.value.location && !block.value.coordinates) {
        return renderFallback();
    }
    const _a = block.value, { location, coordinates, title } = _a, value = __rest(_a, ["location", "coordinates", "title"]), _b = block.options, { spacing, round, surface, useCoordinates } = _b, options = __rest(_b, ["spacing", "round", "surface", "useCoordinates"]), { 
    // next,
    previous, dragging } = block;
    const shouldCollapseMarginTop = !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const style = spacing
        ? {
            paddingTop: shouldCollapseMarginTop ? 0 : spacing * 8,
            paddingBottom: spacing * 8,
        }
        : undefined;
    const { street, number, addition, city } = location || {};
    const [latitude, longitude] = coordinates || [];
    const addressDescription = street
        ? [street, [number !== null && number !== void 0 ? number : '', addition !== null && addition !== void 0 ? addition : ''].join('')]
            .filter(Boolean)
            .join(' ')
            .trim()
        : '';
    const directableLocation = addressDescription
        ? [title, addressDescription, city].filter(Boolean).join(' ').trim()
        : latitude && longitude
            ? { latitude, longitude }
            : (title !== null && title !== void 0 ? title : '').trim();
    const directions = useCoordinates && coordinates && coordinates.length === 2 && coordinates[0]
        ? { latitude: coordinates[0], longitude: coordinates[1] }
        : directableLocation;
    /*
     round: round
        ? {
            top: !extendsFullyToBottom(previous),
            bottom: !extendsFullyToTop(next),
          }
        : undefined,*/
    if (surface) {
        return createElement(Surface, {
            style: [
                style,
                {
                    elevation: 1,
                    paddingHorizontal: 16,
                },
            ],
            children: createElement(DirectionsMenu, Object.assign(Object.assign(Object.assign({}, value), options), { location: directions })),
        });
    }
    return createElement(View, {
        style: [
            {
                paddingHorizontal: 16,
                paddingVertical: (spacing || 0) * 8,
                alignSelf: 'center',
            },
        ],
    }, createElement(DirectionsMenu, Object.assign(Object.assign(Object.assign({}, value), options), { location: directions })));
}
