import { fetchMedia, typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactileUser } from './types';

const ACCEPT = 'application/json';

/**
 * Fetch the user associated with the current authorization
 *
 * @param endpoint the base endpoint
 * @param authorization the user authorization
 * @param signal abort signal
 * @param debug
 */
export async function fetchUser(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileUser> {
  const result = await fetchMedia(endpoint + '/user', {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

/**
 * Fetch the user associated with the current authorization
 *
 * @param endpoint the base endpoint
 * @param authorization the user authorization
 * @param signal abort signal
 * @param debug
 */
export async function fetchApplicationUser(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileUser> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/user/profile`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function submitUserImage(
  userId: string,
  data: FormData,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<unknown> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!userId) {
    throw new ApiClientError('user id missing');
  }

  if (!data.has('filepond')) {
    throw new ApiClientError(
      `Expected multi-part form data with key "filepond"`
    );
  }

  return await fetchMedia(`${endpoint}/image/user/${userId}/image.profile`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    method: 'POST',
    signal,
    debug,
    body: data,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
}
