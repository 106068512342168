import { __rest } from "tslib";
import React from 'react';
import { LocalLocationCard } from './LocalLocationCard';
import { RemoteLocationCard } from './RemoteLocationCard';
export function LocationCard(_a) {
    var { source } = _a, other = __rest(_a, ["source"]);
    if (typeof source !== 'string') {
        return React.createElement(LocalLocationCard, Object.assign({}, source, other));
    }
    return React.createElement(RemoteLocationCard, Object.assign({ id: source }, other));
}
