import { __rest } from "tslib";
import { useBlockData, useBlockDataLocation, } from '@introcloud/blocks-interface';
import React from 'react';
import { LocalLocationCard } from './LocalLocationCard';
const EMPTY_LOCATION_DATA = {
    id: '',
    coordinates: undefined,
    title: '',
    description: '',
};
export function RemoteLocationCard(_a) {
    var { id, renderFallback, renderError } = _a, options = __rest(_a, ["id", "renderFallback", "renderError"]);
    const { data: location, loading, error } = useBlockDataLocation(id);
    const { getImageUrl } = useBlockData();
    if (loading || !location) {
        return React.createElement(LocalLocationCard, Object.assign({}, EMPTY_LOCATION_DATA, options));
    }
    if (!location) {
        if (error && renderError) {
            return renderError(error);
        }
        return renderFallback ? renderFallback() : null;
    }
    const imageId = location.image ? location.image.profile : null;
    const geojson = location.geojson;
    const type = geojson === null || geojson === void 0 ? void 0 : geojson.type;
    const coordinates = type === 'Point' ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates : undefined;
    const line = type === 'LineString'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const areas = type === 'Polygon'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const locationData = {
        id: location._id,
        illustration: imageId
            ? getImageUrl(imageId, 'icon_128') || undefined
            : undefined,
        title: location.name.full,
        description: location.name.description || '',
        coordinates,
        line,
        areas,
        address: {
            street: location.street || undefined,
            number: location.number || undefined,
            addition: location.addition || undefined,
            city: location.city || undefined,
        },
        url: location.url,
    };
    return React.createElement(LocalLocationCard, Object.assign({}, locationData, options));
}
