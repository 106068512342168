import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactileSponsor, TactileNewsItems } from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationNews(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileNewsItems> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/news-item`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchApplicationNewsItem(
  newsItemId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileSponsor> {
  if (!newsItemId) {
    throw new ApiClientError('Missing news item id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/news-item/${newsItemId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}
