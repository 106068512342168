import { createElement } from 'react';
import { View } from 'react-native';
import { List, Paragraph } from 'react-native-paper';
const DEFAULT_STYLE = { display: 'flex', flexDirection: 'column' };
export function QuickContactsBlock(block) {
    const style = DEFAULT_STYLE;
    // TODO suspend?
    return createElement(View, {
        key: 'surface',
        style,
    }, [
        createElement(List.Subheader, { key: 'header', style: { marginTop: 0 } }, 'Quick contacts'),
        createElement(Paragraph, {
            key: 'paragraph',
            style: { marginHorizontal: 16 },
            children: `This is a placeholder. At this moment your group contacts are
not available. In the app, if the user is part of one or more group, a list of
their quick contacts appears here.
`.replace(/\n/g, ' '),
        }),
    ]);
}
