import { createContext, useContext } from 'react';
export const ErrorHandlerContext = createContext({
    handleEmpty: true,
    handleError: true,
    renderEmpty: () => null,
    renderError: () => null,
    reportError: () => Promise.resolve(),
});
export const ErrorHandlerProvider = ErrorHandlerContext.Provider;
export const ErrorHandlerConsumer = ErrorHandlerContext.Consumer;
export function useErrorHandler() {
    return useContext(ErrorHandlerContext);
}
