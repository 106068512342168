import { useBlockNavigation } from '@introcloud/blocks-interface';
import React, { useCallback } from 'react';
import { List } from 'react-native-paper';
import { DirectionsMenu } from './DirectionsMenu';
import { LocationIcon } from './LocationIcon';
export function LocalLocationListItem({ id, title, description, address, coordinates, url, illustration, tags, loading, }) {
    const { gotoLocation } = useBlockNavigation();
    const addressDescription = (address === null || address === void 0 ? void 0 : address.street)
        ? `${address.street} ${address.number || ''}${address.addition || ''}`.trim()
        : '';
    const [latitude, longitude] = coordinates || [];
    const directableLocation = addressDescription
        ? `${title}, ${addressDescription} ${address === null || address === void 0 ? void 0 : address.city}`
        : latitude && longitude
            ? { latitude, longitude }
            : `${title}`;
    const renderLeft = useCallback((props) => (React.createElement(LocationIcon, Object.assign({ loading: loading, tags: tags, illustration: illustration, fallback: true }, props))), [illustration, loading, tags]);
    return (React.createElement(List.Item, { title: title, description: description, onPress: () => gotoLocation(id), left: renderLeft, right: (props) => (React.createElement(DirectionsMenu, Object.assign({}, props, { icon: "directions", location: directableLocation }))) }));
}
