import React, { Fragment } from 'react';
import { View } from 'react-native';
import { List } from 'react-native-paper';
import { IconGrid } from './IconGrid';
import { ImageGrid } from './ImageGrid';
export function ActionGrid({ title, actions, mode, size, resizeMode, renderFallback, }) {
    if (actions.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    return (React.createElement(Fragment, null,
        title ? (React.createElement(View, { style: { width: '100%' } },
            React.createElement(List.Subheader, null, title))) : null,
        mode === 'images' && (React.createElement(ImageGrid, { resizeMode: resizeMode, size: size, actions: actions })),
        mode === 'icons' && (React.createElement(IconGrid, { size: size, actions: actions }))));
}
