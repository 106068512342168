import { KIND_TEXT, KIND_AUTO } from '../Kinds';
export function isHeading(block_) {
    var _a;
    if (!block_) {
        return false;
    }
    // Omit removes the auto type narrowing for the switch
    const block = block_;
    switch (block.kind) {
        case KIND_TEXT: {
            return ((_a = block.options) === null || _a === void 0 ? void 0 : _a.size) && block.options.size !== 'paragraph';
        }
        case KIND_AUTO: {
            return block.value.auto === 'title';
        }
        default: {
            return false;
        }
    }
}
