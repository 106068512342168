import { __rest } from "tslib";
import { useBlockData, useBlockDataEvent, useBlockSettings, } from '@introcloud/blocks-interface';
import React from 'react';
import { EventListItemDescriptionFocus } from './EventListItemDescriptionFocus';
import { EventListItemLocationFocus } from './EventListItemLocationFocus';
export function RemoteEventListItem(_a) {
    var { source, renderFallback, renderError } = _a, other = __rest(_a, ["source", "renderFallback", "renderError"]);
    const { EventListItem: { type, lines }, } = useBlockSettings();
    const { getImageUrl } = useBlockData();
    const { data, loading, error } = useBlockDataEvent(source);
    const finalData = loading || !data
        ? dataOrPlaceholder(Object.assign({ source }, other), lines)
        : extractData(data, other, { getImageUrl });
    if (finalData === null || !source || (!data && !loading)) {
        if (error && renderError) {
            return renderError(error);
        }
        // error?
        return renderFallback ? renderFallback() : null;
    }
    switch (type) {
        case 'description-focus': {
            return (React.createElement(EventListItemDescriptionFocus, Object.assign({}, finalData, other, { loading: loading })));
        }
        case 'location-focus': {
            return (React.createElement(EventListItemLocationFocus, Object.assign({}, finalData, other, { loading: loading })));
        }
    }
}
function dataOrPlaceholder(_a, lines) {
    var { source } = _a, other = __rest(_a, ["source"]);
    if (typeof source !== 'string') {
        return Object.assign(Object.assign(Object.assign({}, source), other), { loading: false });
    }
    return Object.assign(Object.assign({ id: source, title: ' ', description: ' \n ' + (lines === 3 ? '\n ' : ''), duration: {
            start: 0,
            end: 0,
        }, tags: [], locationIds: [], fallbackLocation: undefined }, other), { hasTimeslots: false, loading: true });
}
function extractData(data, other, utils) {
    var _a, _b, _c, _d, _e, _f;
    if (data === null) {
        return null;
    }
    const imageId = data.image ? data.image.profile || data.image.banner : null;
    return Object.assign(Object.assign({ id: data._id, title: data.name.full, description: data.name.description || '', duration: {
            start: ((_b = (_a = data.duration) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.unix) || 0,
            end: ((_d = (_c = data.duration) === null || _c === void 0 ? void 0 : _c.end) === null || _d === void 0 ? void 0 : _d.unix) || 0,
        }, tags: data.name.tag || [], locationIds: (data.locationRef || [])
            .map((ref) => ref.locationId)
            .filter(Boolean), fallbackLocation: undefined, illustration: imageId
            ? utils.getImageUrl(imageId, 'icon_128') || undefined
            : undefined, hasTimeslots: Boolean((_f = (_e = data.module) === null || _e === void 0 ? void 0 : _e.timeslot) === null || _f === void 0 ? void 0 : _f.active) }, other), { loading: false });
}
