import { fetchMedia, typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactileVideo, TactileVideoIndex } from './types';

const ACCEPT = 'application/json';

/**
 * Fetch the chat conversation ids

 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
export async function fetchApplicationVideoIndex(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileVideoIndex> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/video`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchApplicationVideo(
  videoId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileVideo> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!videoId) {
    throw new ApiClientError('Missing video id');
  }

  const result = await fetchMedia(`${endpoint}/application/video/${videoId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}
