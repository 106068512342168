import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { BlockQueryKeys } from '../BlockQueryKeys';
import { useBlockData } from '../BlockData';
export function useBlockDataEvent(eventId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getEventById } = useBlockData();
    const _b = useQuery(BlockQueryKeys.event(eventId), () => getEventById(eventId), Object.assign({ enabled: enabled && !!eventId }, options)), { data: event, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: event, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}
