import { PrimaryButton, Spacer } from '@introcloud/blocks';
import { createElement } from 'react';
import { View } from 'react-native';
import { Avatar, Card, Paragraph, useTheme } from 'react-native-paper';
export function PushNotificationsBlock(block) {
    const { colors: { onSurface }, } = useTheme();
    // TODO suspend
    return createElement(View, undefined, [
        createElement(Card, {
            onPress: () => { },
            children: [
                createElement(Card.Title, {
                    key: 'title',
                    title: 'Get important updates',
                    subtitle: 'Enable push notifications',
                    left: (props) => createElement(Avatar.Icon, Object.assign(Object.assign({}, props), { size: 48, style: { padding: 0, backgroundColor: 'transparent' }, icon: 'bell-alert', color: onSurface })),
                }),
                createElement(Card.Content, {
                    key: 'content',
                    children: createElement(Paragraph, {
                        children: `This is a placeholder. Allow push notifications to get updates about last-minute changes to the
programme, important personal information, or reminders for important
events you're participating in.`.replace(/\n/g, ' '),
                    }),
                }),
                createElement(Card.Actions, {
                    key: 'actions',
                    children: createElement(PrimaryButton, {
                        style: { marginLeft: 'auto' },
                        onPress: () => { },
                        children: 'Enable',
                    }),
                }),
            ],
        }),
        createElement(Spacer, { space: 1 }),
    ]);
}
