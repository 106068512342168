import { useMemo, useCallback } from 'react';
import { Alert, Linking, Platform } from 'react-native';
export function googleMapsIosUrl(coords, address) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const googleMapsIosDestination = `daddr=${param}`;
    return `comgooglemaps://?${googleMapsIosDestination}`;
}
export function googleDirectionsIosUrl(coords, address, travelMode) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const googleMapsIosDestination = `daddr=${param}`;
    const googleMapsIosMode = `directionsmode=${travelMode}`;
    return `comgooglemaps://?${googleMapsIosDestination}&${googleMapsIosMode}`;
}
export function appleMapsUrl(coords, address) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const iosDestination = `daddr=${param}`;
    return `http://maps.apple.com/?${iosDestination}&t=h`;
}
export function appleDirectionsUrl(coords, address, iosTravelFlag) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const iosDestination = `daddr=${param}`;
    const iosDirFlag = `dirflg=${iosTravelFlag}`;
    return `http://maps.apple.com/?${iosDestination}&${iosDirFlag}&t=h`;
}
export function googleMapsUrl(coords, address) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const destination = `query=${param}`;
    return `https://www.google.com/maps/search/?api=1&${destination}`;
}
export function googleDirectionsUrl(coords, address, travelMode) {
    const param = coords
        ? `${coords.latitude},${coords.longitude}`
        : encodeURIComponent(address || '');
    const destination = `destination=${param}`;
    const mode = `travelmode=${travelMode}`;
    return `https://www.google.com/maps/dir/?api=1&${destination}&${mode}`;
}
export function useOpenInMaps(coordsOrAddress, tryIos = Platform.select({ ios: true, default: false }), debug = false) {
    const coords = (typeof coordsOrAddress !== 'string' && coordsOrAddress) || undefined;
    const address = (typeof coordsOrAddress === 'string' && coordsOrAddress) || undefined;
    const iosGoogleUrl = useMemo(() => googleMapsIosUrl(coords, address), [coords, address]);
    const iosUrl = useMemo(() => appleMapsUrl(coords, address), [coords, address]);
    const genericUrl = useMemo(() => googleMapsUrl(coords, address), [coords, address]);
    const genericCallback = useCallback(() => {
        debug && console.debug('[url] generic callback maps', { genericUrl });
        return Linking.canOpenURL(genericUrl)
            .then((supported) => {
            if (supported) {
                debug && console.warn('[url] opening url', { genericUrl });
                return Linking.openURL(genericUrl);
            }
            throw new Error('Browser not installed');
        })
            .catch((err) => Alert.alert("Can't show maps", err));
    }, [genericUrl]);
    const callback = useCallback(() => {
        return (Linking.openURL(iosGoogleUrl)
            /*.then(supported => {
            if (supported) {
              return Linking.openURL(googleMapsIosUrl)
            }
            console.log('Google Maps not installed')
            throw new Error('Google Maps not installed')
          })*/
            .catch((_ignore) => {
            return Linking.canOpenURL(iosUrl)
                .then((supported) => {
                if (supported) {
                    return Linking.openURL(iosUrl);
                }
                console.log('Apple Maps not installed');
                throw new Error('Apple Maps not installed');
            })
                .catch(() => genericCallback());
        }));
    }, [genericCallback, googleMapsIosUrl, iosUrl]);
    if (!tryIos) {
        return genericCallback;
    }
    return callback;
}
export function useGotoDirections(coordsOrAddress, travelMode = 'bicycling', iosTravelFlag = 'w', tryIos = Platform.select({ ios: true, default: false }), debug = false) {
    const coords = (typeof coordsOrAddress !== 'string' && coordsOrAddress) || undefined;
    const address = (typeof coordsOrAddress === 'string' && coordsOrAddress) || undefined;
    const googleMapsIosUrl = useMemo(() => googleDirectionsIosUrl(coords, address, travelMode), [coords, address, travelMode]);
    const iosUrl = useMemo(() => appleDirectionsUrl(coords, address, iosTravelFlag), [coords, address, iosTravelFlag]);
    const genericUrl = useMemo(() => googleDirectionsUrl(coords, address, travelMode), [coords, address, travelMode]);
    const genericCallback = useCallback(() => {
        debug && console.debug('[url] generic callback directions', { genericUrl });
        return Linking.canOpenURL(genericUrl)
            .then((supported) => {
            if (supported) {
                debug && console.warn('[url] opening url', { genericUrl });
                return Linking.openURL(genericUrl);
            }
            throw new Error('Browser not installed');
        })
            .catch((err) => Alert.alert("Can't show directions", err));
    }, [genericUrl]);
    const callback = useCallback(() => {
        return (Linking.openURL(googleMapsIosUrl)
            /*.then(supported => {
            if (supported) {
              return Linking.openURL(googleMapsIosUrl)
            }
            console.log('Google Maps not installed')
            throw new Error('Google Maps not installed')
          })*/
            .catch((_ignore) => {
            return Linking.canOpenURL(iosUrl)
                .then((supported) => {
                if (supported) {
                    return Linking.openURL(iosUrl);
                }
                console.log('Apple Maps not installed');
                throw new Error('Apple Maps not installed');
            })
                .catch(() => genericCallback());
        }));
    }, [genericCallback, googleMapsIosUrl, iosUrl]);
    if (!tryIos) {
        return genericCallback;
    }
    return callback;
}
