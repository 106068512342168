import { useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
export function MapBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (block.value.markers.length === 0) {
        return renderFallback();
    }
    throw new Error('Map block is not yet available.');
}
