import { EventListItem } from '@introcloud/blocks';
import { createElement, Fragment, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Divider, Surface } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
import { hasSurfaceAtBottom } from '../utils/hasSurface';
const DEFAULT_STYLE = {
    elevation: 1,
};
export function EventBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { id }, previous, } = block;
    const needDivider = hasSurfaceAtBottom(previous) && (previous === null || previous === void 0 ? void 0 : previous.kind) !== 'event';
    const style = DEFAULT_STYLE;
    // TODO suspend?
    return createElement(Fragment, undefined, [
        needDivider &&
            createElement(Divider, {
                key: 'divider',
                style: { height: StyleSheet.hairlineWidth },
            }),
        createElement(Surface, {
            key: 'surface',
            style,
            children: createElement(EventListItem, {
                source: id,
                renderFallback,
                isLast: true,
            }),
        }),
    ]);
}
