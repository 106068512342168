import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactileGoal, TactileGoalRanking } from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationEventGoals(
  mainEventId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileGoal> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!mainEventId) {
    throw new ApiClientError('Main-event id missing');
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-goal/list/${mainEventId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchApplicationEventGoalRanking(
  mainEventId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileGoalRanking> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!mainEventId) {
    throw new ApiClientError('Main-event id missing');
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-goal/ranking/${mainEventId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function submitApplicationEventGoal(
  goalId: string,
  kind: TactileGoal['kind'],
  data: string | Blob | File | FormData,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<unknown> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!goalId) {
    throw new ApiClientError('goal id missing');
  }

  if (kind === 'textarea' || kind === 'input') {
    return await fetchMedia(
      `${endpoint}/application/event-goal-submission/${goalId}`,
      {
        headers: {
          accept: ACCEPT,
          authorization,
          contentType: 'application/json; charset=utf-8',
        },
        method: 'POST',
        signal,
        debug,
        body: {
          value: data,
        },

        disableFormData: true,
        disableFormUrlEncoded: true,
      }
    );
  }

  if (kind === 'image' || (kind as any) === 'video') {
    return await fetchMedia(
      `${endpoint}/application/event-goal-submission/${goalId}`,
      {
        headers: {
          accept: ACCEPT,
          authorization,
        },
        method: 'POST',
        signal,
        debug,
        body: data,

        disableFormData: true,
        disableFormUrlEncoded: true,
      }
    );
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-goal-submission/${goalId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
        contentType: 'application/json; charset=utf-8',
      },
      method: 'POST',
      signal,
      debug,
      body: {
        answerId: data,
      },

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}
