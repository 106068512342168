import { fetchMedia, typeCheck } from './core';
import { RequiresDomain } from './errors';
import { TactileCompany, TactileCompanyVisuals } from './types';

const ACCEPT = 'application/json';

/**
 * Fetch the application
 *
 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
export async function fetchApplication(
  endpoint: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileCompany> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  const result = await fetchMedia(`${endpoint}/application`, {
    headers: {
      accept: ACCEPT,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

/**
 * Fetch the application visuals
 *
 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
export async function fetchApplicationVisuals(
  endpoint: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileCompanyVisuals> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  const result = await fetchMedia(`${endpoint}/application/visuals`, {
    headers: {
      accept: ACCEPT,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}
