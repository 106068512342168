import { __rest } from "tslib";
import { useBlockSettings } from '@introcloud/blocks-interface';
import React from 'react';
import { EventListItemDescriptionFocus } from './EventListItemDescriptionFocus';
import { EventListItemLocationFocus } from './EventListItemLocationFocus';
import { RemoteEventListItem } from './RemoteEventListItem';
export function EventListItem(_a) {
    var { source } = _a, other = __rest(_a, ["source"]);
    if (typeof source !== 'string') {
        return React.createElement(LocalEventListItem, Object.assign({ source: source }, other));
    }
    return React.createElement(RemoteEventListItem, Object.assign({ source: source }, other));
}
function LocalEventListItem(_a) {
    var { source } = _a, other = __rest(_a, ["source"]);
    const { EventListItem: { type }, } = useBlockSettings();
    switch (type) {
        case 'description-focus': {
            return (React.createElement(EventListItemDescriptionFocus, Object.assign({}, source, other, { loading: false })));
        }
        case 'location-focus': {
            return (React.createElement(EventListItemLocationFocus, Object.assign({}, source, other, { loading: false })));
        }
    }
}
