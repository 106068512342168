import { Divider as DividerBlock } from '@introcloud/blocks';
import { createElement } from 'react';
import { useTheme } from 'react-native-paper';
export function Divider({ dragging, options }) {
    const { colors: { surface }, } = useTheme();
    return createElement(DividerBlock, Object.assign(Object.assign({}, options), { style: {
            backgroundColor: dragging ? surface : 'transparent',
            paddingTop: dragging ? 4 : 0,
            paddingBottom: dragging ? 4 : 0,
        } }));
}
