import { __rest } from "tslib";
import { ResponsiveImage } from '@introcloud/blocks';
import { useBlockData, useBlockDataSponsorById, useBlockDataSponsorByKind, useBlockNavigation, } from '@introcloud/blocks-interface';
import URLSearchParams from '@ungap/url-search-params';
import { createElement, useCallback } from 'react';
import { TouchableRipple, useTheme, Surface } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function SponsorBlock(block) {
    if (block.value.src) {
        return createElement(RemoteSponsorBlock, block);
    }
    return createElement(RandomSponsorBlock, block);
}
function RemoteSponsorBlock(block) {
    const { getImageUrl } = useBlockData();
    const { data: sponsor, loading } = useBlockDataSponsorById(block.value.src);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const src = getImageUrl((sponsor === null || sponsor === void 0 ? void 0 : sponsor.image.profile) || '', 'icon_1440');
    if (!sponsor || !src) {
        return renderFallback();
    }
    return createElement(SpecificSponsorBlock, Object.assign(Object.assign({}, block), { sponsor, src }));
}
function RandomSponsorBlock(block) {
    const { getImageUrl } = useBlockData();
    const { data: sponsor, loading } = useBlockDataSponsorByKind('page', block.id);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const src = getImageUrl((sponsor === null || sponsor === void 0 ? void 0 : sponsor.image.profile) || '', 'icon_1440');
    if (!src || !sponsor) {
        return renderFallback();
    }
    return createElement(SpecificSponsorBlock, Object.assign(Object.assign({}, block), { sponsor, src }));
}
function SpecificSponsorBlock(_a) {
    var _b;
    var { sponsor, src } = _a, block = __rest(_a, ["sponsor", "src"]);
    const { gotoExternal } = useBlockNavigation();
    const { roundness, colors: { primary: rippleColor }, } = useTheme();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const onPress = useCallback(() => {
        if (!sponsor.url) {
            return;
        }
        const utm = block.value.utm || {};
        const [base, search] = sponsor.url.split('?');
        const searchParams = new URLSearchParams(search);
        searchParams.set('utm_source', utm.source || searchParams.get('utm_source') || 'IntroCloud');
        searchParams.set('utm_content', utm.content ||
            searchParams.get('utm_content') ||
            `block:${block.kind}:${block.id}:sponsor:${sponsor._id}`);
        if (utm.medium) {
            searchParams.set('utm_medium', utm.medium);
        }
        if (utm.campaign) {
            searchParams.set('utm_campaign', utm.campaign);
        }
        const next = `${base}?${searchParams.toString()}`;
        gotoExternal(next);
    }, [gotoExternal, sponsor]);
    if (!src) {
        return renderFallback ? renderFallback() : null;
    }
    const actualRatio = typeof block.options.ratio === 'number'
        ? block.options.ratio
        : block.options.ratio[0] / block.options.ratio[1];
    const previousHasBottomConnection = extendsFullyToBottom(block.previous);
    const nextHasTopConnection = extendsFullyToTop(block.next);
    const borderStyle = ((_b = block.options) === null || _b === void 0 ? void 0 : _b.round) && roundness
        ? {
            borderTopLeftRadius: previousHasBottomConnection ? 0 : roundness,
            borderTopRightRadius: previousHasBottomConnection ? 0 : roundness,
            borderBottomLeftRadius: nextHasTopConnection ? 0 : roundness,
            borderBottomRightRadius: nextHasTopConnection ? 0 : roundness,
        }
        : undefined;
    return createElement(Surface, {
        style: [borderStyle, { elevation: 1 }],
        children: createElement(TouchableRipple, {
            onPress,
            rippleColor,
            children: createElement(ResponsiveImage, Object.assign(Object.assign(Object.assign({}, block.value), block.options), { ratio: actualRatio, renderFallback, round: {
                    top: block.options.round && !previousHasBottomConnection,
                    bottom: block.options.round && !nextHasTopConnection,
                }, src })),
        }),
    });
}
