import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import { TactilePoll } from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationPoll(
  pollId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactilePoll> {
  if (!pollId) {
    throw new ApiClientError('Missing poll id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/poll/${pollId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function answerPoll(
  pollId: string,
  pollAnswerId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<unknown> {
  if (!pollId) {
    throw new ApiClientError('Missing poll id to answer');
  }

  if (!pollAnswerId) {
    throw new ApiClientError('Missing poll answer id to answer');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/poll/response/${pollId}/${pollAnswerId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}
