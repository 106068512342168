import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import {
  TactileInterviewAnswer,
  TactileInterviewAnswers,
  TactileSwipePotential,
} from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationSwipeMatches(
  matchEvent: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
) {
  if (!matchEvent) {
    throw new ApiClientError('Missing match event');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-swipe/${matchEvent}/matches`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchApplicationSwipePotentials(
  matchEvent: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileSwipePotential[]> {
  if (!matchEvent) {
    throw new ApiClientError('Missing match event');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-swipe/${matchEvent}/potential`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function swipe(
  matchEvent: string,
  matchId: string,
  kind: 'like' | 'dislike',
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
) {
  if (!matchEvent) {
    throw new ApiClientError('Missing match event');
  }

  if (!matchId) {
    throw new ApiClientError('Missing match ID');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-swipe/${matchEvent}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
        contentType: 'application/json; charset=utf-8',
      },
      method: 'POST',
      signal,
      debug,
      body: {
        eventSwipe: {
          targetEventGuestRef: {
            eventGuest: matchId,
            eventGuestId: matchId,
          },
          kind,
        },
      },

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function submitSwipeInterview(
  matchEvent: string,
  answers: TactileInterviewAnswer[],
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
) {
  if (!matchEvent) {
    throw new ApiClientError('Missing match event');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const body: TactileInterviewAnswers = {
    module: {
      interview: {
        answer: answers,
      },
    },
  };

  const result = await fetchMedia(
    `${endpoint}/application/event-swipe/${matchEvent}/interview`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
        contentType: 'application/json; charset=utf-8',
      },
      method: 'POST',
      signal,
      debug,
      body,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );

  return typeCheck(result);
}
