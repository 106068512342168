import { __rest } from "tslib";
import { eventHasTimeslots, } from '@introcloud/api-client';
import { PrimaryButton, TextButton } from '@introcloud/blocks';
import { useBlockData, useBlockDataEvent, useBlockDataTimeslots, useBlockNavigation, } from '@introcloud/blocks-interface';
import { mergeTranslations } from '@introcloud/blocks/dist/utils';
import { t } from 'i18n-js';
import { createElement, memo, useCallback, useMemo, useRef, useState, } from 'react';
import { View, Platform } from 'react-native';
import { Avatar, Caption, Dialog, HelperText, List, Portal, Surface, Title, useTheme, } from 'react-native-paper';
import { localeDateString, localeTimeString } from 'react-native-time-helpers';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
import { useTranslation } from '../utils/useTranslation';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                register: 'Register',
                cancel: 'Cancel',
            },
            timeslots: {
                more: {
                    label: 'More...',
                },
                joined: {
                    label: 'Joined',
                },
                wait: {
                    label: 'Please wait...',
                },
                full: {
                    label: 'Full',
                },
                past: {
                    label: 'Expired',
                },
                slots_left: {
                    zero: 'none left',
                    one: '1 left',
                    other: '{{count}} left',
                },
                confirmation: {
                    context: ' at ',
                    caption: 'Do you want to register for this timeslot?',
                },
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                register: 'Inschrijven',
                cancel: 'Annuleer',
            },
            timeslots: {
                more: {
                    label: 'Meer...',
                },
                joined: {
                    label: 'Ingeschreven',
                },
                wait: {
                    label: 'Even geduld...',
                },
                full: {
                    label: 'Vol',
                },
                past: {
                    label: 'Verlopen',
                },
                slots_left: {
                    zero: 'Geen plekken',
                    one: '1 plek',
                    other: '{{count}} plekken',
                },
                confirmation: {
                    context: ' op ',
                    caption: 'Wil je je inschrijven voor dit tijdslot?',
                },
            },
        },
    },
});
export function TimeSlotsBlock(block) {
    const { pageEvent } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { label, localizedLabel, event }, options, next, previous, } = block;
    const header = useTranslation(localizedLabel, label);
    const eventId = (event === 'auto' ? pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent._id : block.value.event) || '';
    const { data, change, error, refreshing } = useBlockDataTimeslots(eventId, undefined, { refetchInterval: 42 * 1000, refetchOnWindowFocus: false });
    const { data: eventData, isLoading } = useBlockDataEvent(eventId, {
        notifyOnChangeProps: ['data'],
    });
    const { roundness } = useTheme();
    const top = !extendsFullyToBottom(previous);
    const bottom = !extendsFullyToTop(next);
    const [isJoining, setIsJoining] = useState(false);
    const lastJoinId = useRef(null);
    const onConfirmJoin = useCallback((id) => {
        lastJoinId.current = id;
        setIsJoining(true);
    }, [setIsJoining, lastJoinId]);
    const doJoin = useCallback(() => {
        lastJoinId.current && change(lastJoinId.current);
        lastJoinId.current = null;
        setIsJoining(false);
    }, [setIsJoining, lastJoinId, change]);
    const register = useMemo(() => {
        const value = eventData &&
            eventHasTimeslots(eventData) &&
            eventData.module.timeslot.register;
        if (!value) {
            return undefined;
        }
        return {
            start: value.start ? new Date(value.start).getTime() : null,
            end: value.end ? new Date(value.end).getTime() : null,
        };
    }, [eventData]);
    if (!eventId ||
        !data ||
        !eventData ||
        !eventHasTimeslots(eventData) ||
        data.timeslots.length === 0) {
        return renderFallback();
    }
    const style = (options || {}).round
        ? {
            borderTopLeftRadius: !header && top ? 0 : roundness,
            borderTopRightRadius: !header && !top ? 0 : roundness,
            borderBottomLeftRadius: !bottom ? 0 : roundness,
            borderBottomRightRadius: !bottom ? 0 : roundness,
        }
        : {};
    const anyCurrent = data.timeslots.find((timeslot) => timeslot.registeredEventGuest);
    return createElement(View, { style: { width: '100%' } }, [
        header
            ? createElement(List.Subheader, { key: 'header', style: { width: '100%' } }, header)
            : null,
        createElement(Surface, {
            key: 'list',
            style: [style, { elevation: 1 }],
            children: data.timeslots.map((timeslot) => {
                const isCurrent = (anyCurrent === null || anyCurrent === void 0 ? void 0 : anyCurrent._id) === timeslot._id;
                return createElement(TimeSlotListItem, {
                    key: timeslot._id,
                    timeslot,
                    isCurrent,
                    anyCurrent: !!anyCurrent,
                    change: onConfirmJoin,
                    refreshing: refreshing || isLoading,
                    register: register || undefined,
                });
            }),
        }),
        error && !refreshing
            ? createElement(Surface, {
                key: 'error-surface',
                style: [style, { elevation: 1, padding: 8 }],
                children: createElement(HelperText, {
                    key: 'error',
                    type: 'error',
                    children: String(error.message),
                }),
            })
            : undefined,
        createElement(JoinTimeslotDialog, {
            key: 'dialog',
            data: data.timeslots.find((slot) => slot._id === lastJoinId.current),
            visible: isJoining,
            onConfirm: () => doJoin(),
            onDismiss: () => setIsJoining(false),
        }),
    ]);
}
function JoinTimeslotDialog({ visible, data, onDismiss, onConfirm, }) {
    var _a, _b;
    const { currentUser } = usePageData();
    const { getImageUrl } = useBlockData();
    const imageUrl = getImageUrl((currentUser === null || currentUser === void 0 ? void 0 : currentUser.image.profile) || '', 'icon_256');
    const initials = [(_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.first) === null || _a === void 0 ? void 0 : _a[0], (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.last) === null || _b === void 0 ? void 0 : _b[1]]
        .filter(Boolean)
        .join('');
    const contextName = [
        data === null || data === void 0 ? void 0 : data.name.full,
        (data === null || data === void 0 ? void 0 : data.start) ? localeTimeString(new Date(data.start.unix)) : undefined,
    ]
        .filter(Boolean)
        .join(t('blocks.timeslots.confirmation.context'));
    return createElement(Portal, undefined, createElement(Dialog, {
        style: {
            alignSelf: 'center',
            maxWidth: 320,
            width: '100%',
        },
        visible: visible && data !== null,
        onDismiss,
        children: [
            createElement(Dialog.Content, {
                key: 'content',
                children: createElement(View, { style: { alignItems: 'center' } }, [
                    imageUrl
                        ? createElement(Avatar.Image, {
                            key: 'image',
                            size: 96,
                            source: { uri: imageUrl },
                        })
                        : createElement(Avatar.Text, {
                            key: 'image',
                            size: 96,
                            label: initials,
                        }),
                    contextName
                        ? createElement(Title, {
                            key: 'title',
                            style: { marginTop: 12, textAlign: 'center' },
                            children: contextName,
                        })
                        : undefined,
                ]),
            }),
            createElement(Caption, {
                key: 'relative',
                style: {
                    marginTop: 16,
                    marginLeft: 16,
                    marginRight: 16,
                    textAlign: 'center',
                },
                children: t('blocks.timeslots.confirmation.caption'),
            }),
            createElement(Dialog.Actions, {
                key: 'actions',
                style: {
                    justifyContent: 'center',
                },
                children: [
                    createElement(TextButton, {
                        key: 'dismiss',
                        style: {
                            marginRight: 8,
                            minWidth: 100,
                        },
                        children: t('blocks.actions.cancel'),
                        onPress: onDismiss,
                    }),
                    createElement(PrimaryButton, {
                        key: 'confirm',
                        style: {
                            marginLeft: 8,
                            minWidth: 100,
                        },
                        children: t('blocks.actions.register'),
                        onPress: onConfirm,
                    }),
                ],
            }),
        ],
    }));
}
function TimeSlotListItem_(_a) {
    var { timeslot, isCurrent, register } = _a, props = __rest(_a, ["timeslot", "isCurrent", "register"]);
    const timestamp = useInaccurateTimestamp({ every: 1000 * 10 });
    const isPast = timeslot.end.unix < timestamp;
    const canRegister = !register ||
        ((!register.start || register.start < timestamp) &&
            (!register.end || register.end > timestamp));
    const description = timeslot.name.full
        ? `${timeslot.name.full} (${localeDateString(new Date(timeslot.start.unix), false, false)})`
        : localeDateString(new Date(timeslot.start.unix), true, false);
    // In the past, you can only access the data if you were a guest.
    if (isPast) {
        /*
        if (isCurrent) {
          return createElement(MoreTimeSlot, {
            timeslot,
            description,
            isCurrent,
            ...props,
          });
        }
        */
        return createElement(PastTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    const slotsLeft = timeslot.count.number - timeslot.registeredAmount;
    const isNowOrPast = timeslot.start.unix < timestamp;
    // If you're a guest, either wait or get access
    if (isCurrent) {
        // Can access the timeslot content
        if (isNowOrPast) {
            return createElement(MoreTimeSlot, Object.assign({ timeslot,
                description,
                isCurrent }, props));
        }
        // Wait to access the content
        return createElement(JoinedTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    // Registration not open
    if (!canRegister) {
        return createElement(WaitTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    // Can still join
    if (slotsLeft > 0) {
        return createElement(JoinTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    // Full
    return createElement(FullTimeSlot, Object.assign({ timeslot,
        description,
        isCurrent }, props));
}
function MoreTimeSlot({ timeslot, refreshing, description, }) {
    const { gotoTimeslot } = useBlockNavigation();
    const hasPage = timeslot.pageRef && timeslot.pageRef.page;
    const actionText = hasPage
        ? t('blocks.timeslots.more.label')
        : t('blocks.timeslots.joined.label');
    const loading = refreshing === true || refreshing === timeslot._id;
    const onPress = useCallback(() => {
        gotoTimeslot(timeslot._id);
    }, [timeslot._id]);
    return createElement(List.Item, {
        style: Platform.OS === 'web' ? { cursor: 'auto' } : {},
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-marked-outline` })),
        right: () => createElement(PrimaryButton, {
            loading,
            children: actionText,
            onPress: hasPage ? onPress : undefined,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
function PastTimeSlot({ timeslot, description, }) {
    const actionText = t('blocks.timeslots.past.label');
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
function WaitTimeSlot({ timeslot, description, refreshing, }) {
    const actionText = t('blocks.timeslots.wait.label');
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
function JoinedTimeSlot({ timeslot, description, refreshing, }) {
    const actionText = t('blocks.timeslots.joined.label');
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-marked-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
function FullTimeSlot({ timeslot, description, refreshing, }) {
    const actionText = t('blocks.timeslots.full.label');
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
function JoinTimeSlot({ timeslot, description, refreshing, change, }) {
    const slotsLeft = timeslot.count.number - timeslot.registeredAmount;
    const actionText = t('blocks.timeslots.slots_left', { count: slotsLeft });
    const onPress = useCallback(() => {
        change(timeslot._id);
    }, [change, timeslot._id]);
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: () => createElement(PrimaryButton, {
            onPress,
            disabled: !!refreshing,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${(timeslot.start.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')} - ${(timeslot.end.time || '')
            .split(':')
            .slice(0, 2)
            .join(':')}`,
        description,
    });
}
const TimeSlotListItem = memo(TimeSlotListItem_);
