import { __rest } from "tslib";
import { Table } from '@introcloud/blocks';
import { useTranslationAction } from '@introcloud/blocks/dist/TextBlock';
import I18n from 'i18n-js';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function TableBlock(block) {
    var _a, _b;
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    // TODO: re-render on translation hook
    useTranslationAction();
    const _c = block.options, { round } = _c, options = __rest(_c, ["round"]), { value: { values: defaultValues, localizedValues, headers: defaultHeaders, localizedHeaders, }, previous, next } = block;
    if (options.columns === 0 || defaultValues.length === 0) {
        return renderFallback();
    }
    const headers = localizedHeaders
        ? (_a = localizedHeaders[I18n.currentLocale()]) !== null && _a !== void 0 ? _a : defaultHeaders
        : defaultHeaders;
    const values = localizedValues
        ? (_b = localizedValues[I18n.currentLocale()]) !== null && _b !== void 0 ? _b : defaultValues
        : defaultValues;
    return createElement(Table, Object.assign(Object.assign({}, options), { headers,
        values, round: round
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined, renderFallback }));
}
