import { useCallback } from 'react';
import { useBlockNavigation } from '../BlockNavigation';
export function useDestination(destination) {
    const navigation = useBlockNavigation();
    return useCallback(() => {
        switch (destination.kind) {
            case 'event': {
                return navigation.gotoEvent(destination.value);
            }
            case 'location': {
                return navigation.gotoLocation(destination.value);
            }
            case 'info': {
                return navigation.gotoInfo(destination.value);
            }
            case 'external': {
                return navigation.gotoExternal(destination.value);
            }
            case 'live': {
                return navigation.gotoLive(destination.value);
            }
            case 'chat': {
                return navigation.gotoChat(destination.value);
            }
            case 'tab': {
                return navigation.gotoTab(destination.value);
            }
            case 'internal': {
                return navigation.gotoInternal(destination.value);
            }
            case 'emit': {
                return navigation.emit(destination.value);
            }
        }
    }, [navigation, destination]);
}
