import { fetchMedia, typeCheck } from './core';
import {
  RequiresDomain,
  RequiresAuthentication,
  ApiClientError,
} from './errors';
import {
  TactileConversation,
  TactileChatInfo,
  TactileChatProfile,
} from './types';

const ACCEPT = 'application/json';
const CONTENT_TYPE = 'application/json';

export async function fetchChatName(
  modelName: 'page' | 'user' | 'group',
  modelId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<string> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!modelName) {
    throw new ApiClientError('Missing chat model name');
  }

  if (!modelId) {
    throw new ApiClientError('Missing chat model id');
  }

  const result = await fetchMedia(
    `${endpoint}/user/chat/name/${modelName}/${modelId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchChatInfo(
  modelName: 'page' | 'user' | 'group',
  modelId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileChatInfo> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!modelName) {
    throw new ApiClientError('Missing chat model name');
  }

  if (!modelId) {
    throw new ApiClientError('Missing chat model id');
  }

  const result = await fetchMedia(
    `${endpoint}/user/chat/info/${modelName}/${modelId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchChatProfile(
  modelName: 'page' | 'user' | 'group',
  modelId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileChatProfile> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!modelName) {
    throw new ApiClientError('Missing chat model name');
  }

  if (!modelId) {
    throw new ApiClientError('Missing chat model id');
  }

  const result = await fetchMedia(
    `${endpoint}/user/chat/profile/${modelName}/${modelId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchChatConversationWithUser(
  userId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileConversation> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!userId) {
    throw new ApiClientError('Missing main event id');
  }

  const result = await fetchMedia(`${endpoint}/user/chat/user/${userId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchChatConversationWithGroup(
  groupId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileConversation> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!groupId) {
    throw new ApiClientError('Missing group id');
  }

  const result = await fetchMedia(`${endpoint}/user/chat/group/${groupId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchChatConversationWithPageAsGroup(
  pageId: string,
  groupId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileConversation> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!pageId) {
    throw new ApiClientError('Missing page id');
  }

  if (!groupId) {
    throw new ApiClientError('Missing group id');
  }

  const result = await fetchMedia(
    `${endpoint}/user/chat/page/${pageId}/group/${groupId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchChatConversationWithPageAsPublic(
  pageId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileConversation> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!pageId) {
    throw new ApiClientError('Missing main event id');
  }

  const result = await fetchMedia(
    `${endpoint}/user/chat/page/${pageId}/everyone`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchChatConversationWithPageAsSolo(
  pageId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileConversation> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!pageId) {
    throw new ApiClientError('Missing main event id');
  }

  const result = await fetchMedia(`${endpoint}/user/chat/page/${pageId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function triggerChatNotifications(
  channelId: string,
  message: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<void> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  if (!channelId) {
    throw new ApiClientError('Missing channel id');
  }

  await fetchMedia(`${endpoint}/application/pub-nub/${channelId}`, {
    method: 'POST',
    headers: {
      accept: ACCEPT,
      contentType: CONTENT_TYPE,
      authorization,
    },
    signal,
    debug,
    body: { message },

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
}
