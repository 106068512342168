import { useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
export function CustomBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!block.value) {
        return renderFallback();
    }
    throw new Error('Custom blocks should be handled inside the app.');
}
