import { localeDateString, localeTimeString, localeWeekDayString, localeLongWeekDayString, } from 'react-native-time-helpers';
import I18n, { t } from 'i18n-js';
import merge from 'lodash.merge';
export function mergeTranslations(translations) {
    Object.keys(translations).forEach((language) => {
        var _a;
        // Ensure language exists
        I18n.translations[language] = (_a = I18n.translations[language]) !== null && _a !== void 0 ? _a : {};
        // Deep merge
        I18n.translations[language] = merge(I18n.translations[language], translations[language]);
    });
}
mergeTranslations({
    en: {
        date: {
            names: {
                day: {
                    short: {
                        sunday: 'Sun',
                        monday: 'Mon',
                        tuesday: 'Tue',
                        wednesday: 'Wed',
                        thursday: 'Thu',
                        friday: 'Fri',
                        saturday: 'Sat',
                    },
                    long: {
                        sunday: 'Sunday',
                        monday: 'Monday',
                        tuesday: 'Tuesday',
                        wednesday: 'Wednesday',
                        thursday: 'Thursday',
                        friday: 'Friday',
                        saturday: 'Saturday',
                    },
                },
                month: {
                    long: {
                        january: 'January',
                        february: 'February',
                        march: 'March',
                        april: 'April',
                        may: 'May',
                        june: 'June',
                        july: 'July',
                        august: 'August',
                        september: 'September',
                        october: 'October',
                        november: 'November',
                        december: 'December',
                    },
                },
            },
        },
        time: {
            smart: {
                today: 'Today, {{time}}',
                next: 'Next {{day}}, {{time}}',
                last: 'Last {{day}}, {{time}}',
            },
        },
    },
    nl: {
        date: {
            names: {
                day: {
                    short: {
                        sunday: 'zon',
                        monday: 'maa',
                        tuesday: 'din',
                        wednesday: 'woe',
                        thursday: 'don',
                        friday: 'vri',
                        saturday: 'zat',
                    },
                    long: {
                        sunday: 'zondag',
                        monday: 'maandag',
                        tuesday: 'dinsdag',
                        wednesday: 'woensdag',
                        thursday: 'donderdag',
                        friday: 'vrijdag',
                        saturday: 'zaterdag',
                    },
                },
                month: {
                    long: {
                        january: 'januari',
                        february: 'februari',
                        march: 'maart',
                        april: 'april',
                        may: 'mei',
                        june: 'juni',
                        july: 'juli',
                        august: 'augustus',
                        september: 'september',
                        october: 'oktober',
                        november: 'november',
                        december: 'december',
                    },
                },
            },
        },
        time: {
            smart: {
                today: 'Vandaag, {{time}}',
                next: 'Volgende {{day}}, {{time}}',
                last: 'Vorige {{day}}, {{time}}',
            },
        },
    },
});
export function rgb(color) {
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}
export function rgba(color) {
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
}
const ONE_DAY = 1000 * 60 * 60 * 24;
const SIX_DAYS = ONE_DAY * 6;
export function localeSmartTimeString(date, now, relativeTo = null, long = false, withRecent = true) {
    const compareDateString = localeDateString(date, false);
    // Same day as relative to, so don't need to repeat date
    if (relativeTo && compareDateString === localeDateString(relativeTo, false)) {
        return localeTimeString(date);
    }
    const nowDate = new Date(now);
    // Same day as now
    if (localeDateString(nowDate, false) === compareDateString) {
        return t('time.smart.today', { time: localeTimeString(date) });
    }
    const unix = date.getTime();
    const recent = withRecent && Math.abs(unix - now) < SIX_DAYS;
    const sameYear = date.getFullYear() === nowDate.getFullYear();
    // Within a week of now
    if (recent) {
        // Is the end date (and not the start date)
        if (relativeTo) {
            return `${localeWeekDayString(date, {
                shortDayNames: localeShortDayNames(),
            })}, ${localeTimeString(date)}`;
        }
        const base = long
            ? localeLongWeekDayString(date, {
                longDayNames: localeLongDayNames(),
            })
            : localeWeekDayString(date, {
                shortDayNames: localeShortDayNames(),
            });
        if (now < unix) {
            return t('time.smart.next', { day: base, time: localeTimeString(date) });
        }
        return t('time.smart.last', { day: base, time: localeTimeString(date) });
    }
    const showYear = !sameYear &&
        (!relativeTo || relativeTo.getFullYear() !== date.getFullYear());
    const showDay = !relativeTo || date.getUTCMonth() === relativeTo.getUTCMonth();
    return `${localeDateString(date, showDay, showYear, {
        shortDayNames: localeShortDayNames(),
        monthNames: localeMonthNames(),
    })} ${localeTimeString(date)}`;
}
export function cleanTag(tag) {
    if (!tag) {
        return '';
    }
    return tag.trim().toLocaleLowerCase().replace(/ /g, '-');
}
export function normalizeTag(tag) {
    const result = tag.replace(/-/g, ' ');
    if (!result) {
        return '';
    }
    return result[0].toLocaleUpperCase() + result.slice(1).replace(/-/g, ' ');
}
function localeShortDayNames() {
    return Object.values(t('date.names.day.short'));
}
function localeLongDayNames() {
    return Object.values(t('date.names.day.long'));
}
function localeMonthNames() {
    return Object.values(t('date.names.month.long'));
}
