import { ResponsiveIframe } from '@introcloud/blocks';
import URLSearchParams from '@ungap/url-search-params';
import { createElement, useCallback } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
export function VideoEmbedBlock(block) {
    const { colors: { primary }, } = useTheme();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!block.value.src) {
        return renderFallback();
    }
    const src = prepare(block.value.src, block.value.type, primary, Platform.OS === 'ios', block.options.autoplay);
    const actualRatio = typeof block.options.ratio === 'number'
        ? block.options.ratio
        : block.options.ratio[0] / block.options.ratio[1];
    return createElement(ResponsiveIframe, Object.assign(Object.assign(Object.assign({}, block.value), block.options), { ratio: actualRatio, renderFallback,
        src }));
}
export function prepare(src, type, color, supportsMuxNatively, autoplay = false) {
    const [base, search] = src.split('?');
    const realType = determineType(type, base);
    const params = new URLSearchParams(search);
    switch (realType) {
        case 'youtube': {
            params.set('enablejsapi', '1');
            params.set('modestbranding', '1');
            params.set('playsinline', '1');
            if (autoplay) {
                params.set('autoplay', '1');
            }
            return `${base}?${params.toString()}`
                .replace('youtube.com', 'www.youtube-nocookie.com')
                .replace('www.www.youtube', 'www.youtube');
        }
        case 'vimeo': {
            params.set('color', `${color}`);
            params.set('title', '0');
            params.set('byline', '0');
            params.set('portrait', '0');
            params.set('playsinline', '1');
            if (autoplay) {
                params.set('autoplay', '1');
            }
            return `${base}?${params.toString()}`;
        }
        case 'twitch': {
            if (params.has('parent')) {
                params.delete('parent');
            }
            if (Platform.OS === 'web') {
                params.set('parent', window.location.hostname);
                return `${base}?${params.toString()}`;
            }
            return `https://clean.tactile.events/twitch/${encodeURIComponent(`${base}?${params.toString()}`)}`;
        }
        case 'mux': {
            if (supportsMuxNatively) {
                return src;
            }
            return `https://clean.tactile.events/mux/${encodeURIComponent(`${base}?${params.toString()}`)}`;
        }
        default: {
            return src;
        }
    }
}
function determineType(type, src) {
    if (type !== 'other') {
        return type;
    }
    if (src.includes('youtube.com/') || src.includes('youtube-nocookie.com/')) {
        return 'youtube';
    }
    if (src.includes('vimeo')) {
        return 'vimeo';
    }
    if (src.includes('twitch')) {
        return 'twitch';
    }
    return type;
}
