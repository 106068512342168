export class ApiClientError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiClientError.prototype);
  }
}

export class RequiresDomain extends ApiClientError {
  constructor() {
    super('Domain is not given but is required.');
    Object.setPrototypeOf(this, RequiresDomain.prototype);
  }
}

export class RequiresAuthentication extends ApiClientError {
  constructor() {
    super('Only users with a valid token can make this call.');
    Object.setPrototypeOf(this, RequiresAuthentication.prototype);
  }
}

export class FailedTypeCheck extends ApiClientError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, FailedTypeCheck.prototype);
  }
}
