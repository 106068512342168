import { useBlockDataGameMap } from '@introcloud/blocks-interface';
import { createElement, useCallback } from 'react';
import { Surface, Text } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
const DEFAULT_STYLE = {
    elevation: 1,
};
export function GameMapBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { id }, } = block;
    const { error, data } = useBlockDataGameMap(id);
    if (!id) {
        return renderFallback();
    }
    if (error) {
        return renderFallback();
    }
    const style = DEFAULT_STYLE;
    // TODO suspend?
    return createElement(Surface, {
        key: 'surface',
        style,
        children: createElement(Text, {}, `[block placeholder] ${block.kind} (mapId: ${id} [${!data ? 'loading' : `${data.shape.width}:${data.shape.height}`}])`),
    });
}
