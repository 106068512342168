"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInaccurateTimestamp = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var use_timestamp_1 = require("use-timestamp");
var use_timestamp_2 = require("use-timestamp");
Object.defineProperty(exports, "getCurrentTime", { enumerable: true, get: function () { return use_timestamp_2.getCurrentTime; } });
Object.defineProperty(exports, "useTimestamp", { enumerable: true, get: function () { return use_timestamp_2.useTimestamp; } });
Object.defineProperty(exports, "setTimeshift", { enumerable: true, get: function () { return use_timestamp_2.setTimeshift; } });
Object.defineProperty(exports, "setTime", { enumerable: true, get: function () { return use_timestamp_2.setTime; } });
function useInaccurateTimestamp(_a) {
    var every = _a.every;
    var _b = react_1.useState(function () { return use_timestamp_1.getCurrentTime(); }), timestamp = _b[0], setTimestamp = _b[1];
    react_1.useEffect(function () {
        var active = true;
        // Doesn't update the timestamp until interactions are done
        var timeout = setTimeout(function () {
            react_native_1.InteractionManager.runAfterInteractions(function () { return active && setTimestamp(function () { return use_timestamp_1.getCurrentTime(); }); });
        }, every);
        return function () {
            active = false;
            clearTimeout(timeout);
        };
    }, [timestamp]);
    return timestamp;
}
exports.useInaccurateTimestamp = useInaccurateTimestamp;
