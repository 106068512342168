import React, { createContext, useContext, useMemo } from 'react';
export class BlockDataNotProvidedError extends Error {
    constructor(which) {
        super(`${which} has not been provided via a BlockDataProvider`);
        Object.setPrototypeOf(this, BlockDataNotProvidedError.prototype);
    }
}
export const DEFAULT_BLOCK_DATA = {
    getCheckinStatus() {
        return Promise.reject(new BlockDataNotProvidedError('getCheckinStatus'));
    },
    setCheckinStatus() {
        return Promise.reject(new BlockDataNotProvidedError('setCheckinStatus'));
    },
    getEventById() {
        return Promise.reject(new BlockDataNotProvidedError('getEventById'));
    },
    getFirstVisibleEvent() {
        return Promise.reject(new BlockDataNotProvidedError('getFirstVisibleEvent'));
    },
    getImageUrl() {
        console.error(new BlockDataNotProvidedError('getImageUrl'));
        return null;
    },
    getInfoById() {
        return Promise.reject(new BlockDataNotProvidedError('getInfoById'));
    },
    getGameMapById() {
        return Promise.reject(new BlockDataNotProvidedError('getGameMapById'));
    },
    getLocationById() {
        return Promise.reject(new BlockDataNotProvidedError('getLocationById'));
    },
    getLocationEventsById() {
        return Promise.reject(new BlockDataNotProvidedError('getLocationEventsById'));
    },
    getSponsorById() {
        return Promise.reject(new BlockDataNotProvidedError('getSponsorById'));
    },
    getSponsor() {
        return Promise.reject(new BlockDataNotProvidedError('getSponsor'));
    },
    getSubEventsById() {
        return Promise.reject(new BlockDataNotProvidedError('getSubEventsById'));
    },
    getTimeslots() {
        return Promise.reject(new BlockDataNotProvidedError('getTimeslots'));
    },
    getTimeslotsContent() {
        return Promise.reject(new BlockDataNotProvidedError('getTimeslotsContent'));
    },
    setTimeslotRegistration() {
        return Promise.reject(new BlockDataNotProvidedError('setTimeslotRegistration'));
    },
    getVideoPreviewById() {
        return Promise.reject(new BlockDataNotProvidedError('getVideoPreviewById'));
    },
    getBalance() {
        return Promise.reject(new BlockDataNotProvidedError('getBalance'));
    },
};
const BlockDataContext = createContext(DEFAULT_BLOCK_DATA);
export const BlockDataProvider = BlockDataContext.Provider;
export function useBlockData() {
    return useContext(BlockDataContext);
}
export function useHasProvidedBlockData() {
    return useBlockData() !== DEFAULT_BLOCK_DATA;
}
export function ProvideBlockData({ provider, children, }) {
    const merged = useMemo(() => (Object.assign(Object.assign({}, DEFAULT_BLOCK_DATA), provider)), [provider]);
    return React.createElement(BlockDataProvider, { value: merged }, children);
}
