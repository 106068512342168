import { useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
export function useRenderFallback(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    return useCallback(() => (handleEmpty ? renderEmpty(block) : null), [
        handleEmpty,
        renderEmpty,
        block,
    ]);
}
