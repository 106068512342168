import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import { RequiresDomain, RequiresAuthentication } from './errors';
import { TactileGroup } from './types';

const ACCEPT = 'application/json';

/**
 * Fetch the user associated with the current authorization
 *
 * @param endpoint the base endpoint
 * @param authorization the user authorization
 * @param signal abort signal
 * @param debug
 */
export async function fetchApplicationGroups(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactileGroup[]> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/user/group`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}
