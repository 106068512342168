import {
  ACCEPT_PROBLEM,
  fetchMedia,
  setDefaultAccept,
  setDefaultHeaders,
} from 'fetch-media';

import { FailedTypeCheck } from './errors';

const ACCEPT_ERRORS = 'application/vnd.tactile.errors.v1+json; q=0.2';

setDefaultAccept(ACCEPT_ERRORS, ACCEPT_PROBLEM);

export function setApiKey(key: string) {
  setDefaultHeaders({ 'Authorization-Api-Key': key });
}

// TODO remove
setApiKey(
  'D3VZLP4HBV6QNHDC881H9OIDMHCIUKCQQAXJAIU5YRELXBSTALWWHESBRGSLXJWNVWREW3ZUVRP6LRYP291QOUPPEAEQ8C5KY12USAX9LBENQ8XYIACUYOO1PYKIOMC4'
);

export { fetchMedia };

// eslint-disable-next-line @typescript-eslint/ban-types
export function typeCheck<T>(result: object | string): T | never {
  if (typeof result === 'object' && result) {
    if (result.hasOwnProperty('data')) {
      return (result as { data: T }).data;
    }

    throw new FailedTypeCheck('Expected data key, got ' + Object.keys(result));
  }

  throw new FailedTypeCheck('Expected object, got a ' + typeof result);
}
