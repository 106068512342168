import { MaterialCommunityIcons, Feather, FontAwesome5, } from '@expo/vector-icons';
import React from 'react';
export function TabIcon({ name, color, family }) {
    switch (family) {
        case 'Feather': {
            return React.createElement(Feather, { name: name, size: 24, color: color });
        }
        case 'FontAwesome5': {
            return React.createElement(FontAwesome5, { name: name, size: 24, color: color });
        }
        default: {
            return React.createElement(MaterialCommunityIcons, { name: name, size: 24, color: color });
        }
    }
}
export function makeTabIcon({ name, family = 'MaterialCommunityIcons', }) {
    const PreparedTabIcon = (props) => (React.createElement(TabIcon, Object.assign({}, props, { name: name, family: family })));
    // Don't inline this so the component has a name
    return PreparedTabIcon;
}
