import { LocationCard, Spacer } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { View } from 'react-native';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function LocationBlock(block) {
    var _a, _b;
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!block.value.id) {
        return renderFallback();
    }
    const needSpacerTop = extendsFullyToBottom(block.previous) && ((_a = block.previous) === null || _a === void 0 ? void 0 : _a.kind) !== 'location';
    const needSpacerBottom = extendsFullyToTop(block.next) || ((_b = block.next) === null || _b === void 0 ? void 0 : _b.kind) === 'location';
    return createElement(View, { style: { width: '100%', paddingHorizontal: 16 } }, [
        needSpacerTop
            ? createElement(Spacer, { key: 'top', space: 1 })
            : undefined,
        // TODO: forward label?
        createElement(LocationCard, { source: block.value.id, key: 'location' }),
        needSpacerBottom
            ? createElement(Spacer, { key: 'bottom', space: 1 })
            : undefined,
    ]);
}
