import { useCallback, useRef } from 'react';
/**
 * Returns a reference to but on a TextInput, and a callback, to focus that
 * referenced input.
 */
export function useFocusInput() {
    const ref = useRef(null);
    const callback = useCallback(() => {
        const { current } = ref;
        if (!current) {
            return;
        }
        current.focus();
    }, [ref]);
    return [ref, callback];
}
export function useFocusAndBlurInput() {
    const [ref, callbackFocus] = useFocusInput();
    const callbackBlur = useCallback(() => {
        const { current } = ref;
        if (!current) {
            return;
        }
        current.blur();
    }, [ref]);
    return [ref, callbackFocus, callbackBlur];
}
