import { useDimensions } from '@introcloud/blocks-interface';
import React from 'react';
import { Image, PixelRatio } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ImageViewer } from './ImageViewer';
export function ResponsiveImage({ round, ratio, src, mode, accessibilityLabel, maxWidth = 720, zoomable = false, renderFallback, }) {
    const { roundness } = useTheme();
    const { width } = useDimensions();
    if (!src) {
        return renderFallback ? renderFallback() : null;
    }
    const actualWidth = Math.min(maxWidth, width);
    const height = PixelRatio.roundToNearestPixel(actualWidth / ratio);
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const sourceSize = src.includes('/icon_')
        ? extractSize(src) || actualWidth
        : actualWidth;
    if (zoomable) {
        return (React.createElement(ImageViewer, { imageWidth: actualWidth, imageHeight: height, cropWidth: actualWidth, cropHeight: height },
            React.createElement(Image, { width: actualWidth, height: height, source: { uri: src, width: sourceSize, height: sourceSize }, style: {
                    width: actualWidth,
                    height,
                    borderTopLeftRadius: topRadius,
                    borderTopRightRadius: topRadius,
                    borderBottomLeftRadius: bottomRadius,
                    borderBottomRightRadius: bottomRadius,
                    overflow: 'hidden',
                }, resizeMode: mode, accessible: !!accessibilityLabel || undefined, accessibilityLabel: accessibilityLabel, accessibilityRole: "image" })));
    }
    return (React.createElement(Image, { source: { uri: src, width: sourceSize, height: sourceSize }, style: {
            width: actualWidth,
            height,
            borderTopLeftRadius: topRadius,
            borderTopRightRadius: topRadius,
            borderBottomLeftRadius: bottomRadius,
            borderBottomRightRadius: bottomRadius,
            overflow: 'hidden',
        }, resizeMode: mode, accessible: !!accessibilityLabel || undefined, accessibilityLabel: accessibilityLabel, accessibilityRole: "image" }));
}
function extractSize(src) {
    const result = /\/icon_([0-9]+)(?:$|\/)/.exec(src);
    return result ? Number(result[1]) : undefined;
}
/*
function ZoomableImage({
  width,
  height,
  ...props
}: Pick<
  ImageProps,
  | 'source'
  | 'style'
  | 'resizeMode'
  | 'accessible'
  | 'accessibilityLabel'
  | 'accessibilityRole'
> & { width: number; height: number }) {
  const pinchRef: RefObject<PinchGestureHandler> = useRef(null);
  const state: Animated.Value<State> = useValue(State.UNDETERMINED);
  const origin = vec.createValue(0, 0);
  const pinch = vec.createValue(0, 0);
  const focal = vec.createValue(0, 0);
  const scale = new Value(1);
  const numberOfPointers = new Value(0);

  const pinchGestureHandler = onGestureEvent({
    numberOfPointers,
    scale,
    state,
    focalX: focal.x,
    focalY: focal.y,
  });

  const zIndex = cond(eq(state, State.ACTIVE), 3, 0);

  const adjustedFocal = vec.add(
    {
      x: -width / 2,
      y: -height / 2,
    },
    focal
  );

  useCode(
    () =>
      block([
        cond(pinchBegan(state), vec.set(origin, adjustedFocal)),
        cond(
          pinchActive(state, numberOfPointers),
          vec.set(pinch, vec.minus(vec.sub(origin, adjustedFocal)))
        ),
        cond(eq(state, State.END), [
          set(pinch.x, timing({ from: pinch.x, to: 0 })),
          set(pinch.y, timing({ from: pinch.y, to: 0 })),
          set(scale, timing({ from: scale, to: 1 })),
        ]),
      ]),
    [adjustedFocal, numberOfPointers, origin, pinch, scale, state]
  );

  return (
    <Animated.View style={{ width, height, zIndex, position: 'relative' }}>
      <PinchGestureHandler ref={pinchRef} {...pinchGestureHandler}>
        <Animated.View style={StyleSheet.absoluteFill}>
          <Animated.Image
            {...props}
            style={[
              props.style,
              {
                transform: [
                  ...translate(pinch),
                  ...transformOrigin(origin, { scale }),
                ],
              },
            ]}
          />
        </Animated.View>
      </PinchGestureHandler>
      <IconButton
        icon="image-size-select-large"
        style={{ position: 'absolute', right: 32, bottom: 32 }}
      />
    </Animated.View>
  );
}
*/
