import { LocationList } from '@introcloud/blocks';
import { useBlockDataEventLocationsIds } from '@introcloud/blocks-interface';
import { useCallback, createElement } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
import { useTranslation } from '../utils/useTranslation';
export function LocationsBlock(block) {
    var _a;
    const { pageEvent } = usePageData();
    const mode = pageEvent
        ? ((_a = block.options) === null || _a === void 0 ? void 0 : _a.mode) || 'manual'
        : 'manual';
    if (mode === 'event') {
        return createElement(ContextLocationsBlock, block);
    }
    return createElement(ManualLocationsBlock, block);
}
export function ManualLocationsBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const title = useTranslation(block.value.localizedLabel, block.value.label);
    if (!block.value.ids || block.value.ids.length === 0) {
        return renderFallback();
    }
    const { value: { ids }, options, previous, next, } = block;
    return createElement(LocationList, {
        locations: ids,
        title,
        round: (options === null || options === void 0 ? void 0 : options.round)
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined,
        big: (options === null || options === void 0 ? void 0 : options.big) || false,
        renderFallback,
    });
}
function ContextLocationsBlock(block) {
    const { pageEvent } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!pageEvent) {
        return renderFallback();
    }
    return createElement(EventLocationsBlock, Object.assign({ event: pageEvent }, block));
}
function EventLocationsBlock(block) {
    const locations = useBlockDataEventLocationsIds(block.event);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const title = useTranslation(block.value.localizedLabel, block.value.label);
    if (!locations || locations.length === 0) {
        return renderFallback();
    }
    const { options, previous, next } = block;
    return createElement(LocationList, {
        locations,
        title,
        round: (options === null || options === void 0 ? void 0 : options.round)
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined,
        big: (options === null || options === void 0 ? void 0 : options.big) || false,
        renderFallback,
    });
}
