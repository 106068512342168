import React from 'react';
import { StyleSheet, View } from 'react-native';
export function ColorPreview({ color, background, style, }) {
    return (React.createElement(View, { style: [
            Object.assign(Object.assign({}, style), { backgroundColor: background }),
            styles.preview,
        ] },
        React.createElement(View, { style: [styles.dot, { backgroundColor: color }] })));
}
const styles = StyleSheet.create({
    preview: {
        width: 32,
        height: 32,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        width: 20,
        height: 20,
        borderRadius: 10,
    },
});
