import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import {
  TactileEvent,
  TactileAttendanceResponse,
  TactileAttendanceChangeResponse,
  TactileModuleSwipe,
  TactileModuleEventInterview,
  TactileModuleEventGoal,
  TactileModuleTimeslot,
  TactileModuleEventAttendance,
  TactilePageRef,
  TactilePopulatedPageRef,
} from './types';

const ACCEPT = 'application/json';

/**
 *
 * @see {fetchApplicationEvents}
 *
 * @param endpoint
 * @param authorization
 * @param signal
 * @param debug
 */
export async function fetchPublicEvents(
  endpoint: string,
  authorization: string | null,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactileEvent[]> {
  const result = await fetchMedia(`${endpoint}/public/event/list`, {
    headers: {
      accept: ACCEPT,
      authorization: authorization || undefined,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck<readonly TactileEvent[]>(result);
}

export async function fetchPersonalEventById(
  id: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileEvent> {
  const result = await fetchMedia(
    endpoint + `/user/event/${encodeURIComponent(id)}/read`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck<TactileEvent>(result);
}

export async function fetchApplicationEvents(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactileEvent[]> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/event`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchEditorEvents(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<readonly TactileEvent[]> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/editor/event`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export async function fetchApplicationEvent(
  eventId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileEvent> {
  if (!eventId) {
    throw new ApiClientError('Missing event id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(`${endpoint}/application/event/${eventId}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

export function findMainEvents(events: readonly TactileEvent[]) {
  return events.filter((event) => event.eventRef.eventId === null);
}

/**
 * Get the regular listed events
 * @param mainEventId the main event
 * @param events
 */
export function findRegularEvents(
  mainEventId: string,
  events: readonly TactileEvent[]
) {
  return findSubEvents(mainEventId, events);
}

export function matchEventOnId(id: string, events: readonly TactileEvent[]) {
  return events.filter((event) => event._id === id);
}

/**
 * Get the sub events of an event
 * @param eventId event as parent
 * @param events children
 */
export function findSubEvents(
  eventId: string,
  events: readonly TactileEvent[]
) {
  return events.filter((event) => event.eventRef.eventId === eventId);
}

export async function mutateApplicationEventAttendance(
  eventId: string,
  state: 'go' | 'arrive' | 'left',
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileAttendanceChangeResponse> {
  if (!eventId) {
    throw new ApiClientError('Missing event id to attend');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/event/${eventId}/attendance/${state}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function fetchApplicationEventAttendance(
  eventId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileAttendanceResponse> {
  if (!eventId) {
    throw new ApiClientError('Missing event id to get attendance for');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/event-guest/${eventId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export function eventHasAttendance(
  event: TactileEvent
): event is TactileEvent & {
  module: { attendance: TactileModuleEventAttendance };
} {
  return 'attendance' in event.module && event.module.attendance.active;
}

export function eventHasInterview(
  event: TactileEvent
): event is TactileEvent & {
  module: { interview: TactileModuleEventInterview };
} {
  return 'interview' in event.module && event.module.interview.active;
}

export function eventHasGoals(event: TactileEvent): event is TactileEvent & {
  module: { goal: TactileModuleEventGoal };
} {
  return 'goal' in event.module && event.module.goal.active;
}

export function eventHasSwipe(
  event: TactileEvent
): event is TactileEvent & { module: { swipe: TactileModuleSwipe } } {
  return 'swipe' in event.module && event.module.swipe.active;
}

export function eventHasTimeslots(
  event: TactileEvent
): event is TactileEvent & {
  module: { timeslot: TactileModuleTimeslot };
} {
  return 'timeslot' in event.module && event.module.timeslot.active;
}

export function eventHasPageRef(
  event: TactileEvent
): event is TactileEvent & { pageRef: TactilePageRef } {
  return Boolean(event.pageRef.pageId);
}

export function eventHasPage(
  event: TactileEvent
): event is TactileEvent & { pageRef: TactilePopulatedPageRef } {
  return 'page' in event.pageRef && Boolean(event.pageRef.page._id);
}
