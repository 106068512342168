import { fetchMedia, typeCheck } from './core';
import { RequiresAuthentication, RequiresDomain } from './errors';

type PaymentMethodsResponseOk = readonly PaymentMethod[];

interface PaymentMethod {
  banks: PaymentMethodBank[];
  imageUrl: string;
  methodId: string;
  name: string;
}

interface PaymentMethodBank {
  bankId: string;
  name: string;
  imageUrl: string;
}

export interface PaymentMethodsStateValid {
  ok: true;
  methods: readonly PaymentMethod[];
}

export interface PaymentMethodsStateInvalid {
  ok: false;
  message: string;
}

export type PaymentMethodsState =
  | PaymentMethodsStateValid
  | PaymentMethodsStateInvalid;

function isPaymentMethodsOk(
  response: string | object
): response is PaymentMethodsResponseOk {
  return !!(
    typeof response === 'object' &&
    response &&
    'data' in response &&
    (response as { data: [] }).data.length > 0
  );
}

const ACCEPT = 'application/json';

/**
 * Try to authenticate the user
 *
 * @param email the user's email
 * @param password the user's password
 *
 * @returns The normalized Authentication Result
 */
export function fetchPaymentMethods(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): ReturnType<typeof getPaymentMethods_> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  return getPaymentMethods_(endpoint, authorization, signal, debug).catch(
    (err) => {
      return {
        ok: false,
        message: 'message' in err ? err.message : err + '',
      } as const;
    }
  );
}

async function getPaymentMethods_(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<PaymentMethodsState> {
  const result = await fetchMedia(`${endpoint}/user/payment/paynl/method`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });

  if (isPaymentMethodsOk(result)) {
    const response: Readonly<PaymentMethodsResponseOk> = typeCheck(result);

    return {
      ok: true,
      methods: response,
    } as const;
  }

  return {
    ok: false,
    message:
      (typeof result === 'string'
        ? result
        : (result as { message: string }).message) || 'Something went wrong',
  } as const;
}

export type TopUpRequest = {
  methodId: string;
  bankId?: string;
  cents: number;
  returnUrl?: string;
};

export async function topUp(
  endpoint: string,
  authorization: string,
  { methodId, bankId, cents, returnUrl }: TopUpRequest,
  signal?: AbortSignal,
  debug?: boolean
) {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const query = [
    `methodId=${encodeURIComponent(methodId)}`,
    bankId ? `bankId=${encodeURIComponent(bankId)}` : null,
    returnUrl ? `returnUrl=${encodeURIComponent(returnUrl)}` : null,
  ]
    .filter(Boolean)
    .join('&');

  const result = await fetchMedia(
    `${endpoint}/user/payment/paynl/create/${encodeURIComponent(
      cents
    )}?${query}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      method: 'GET',
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
      disableText: true,
    }
  );

  if ('error' in result) {
    const errorResult = result as { error: string; message: string };
    throw new Error(
      `${errorResult.error}: ${errorResult.message.replace(
        'User does',
        'You do'
      )}`
    );
  }

  if (!('data' in result)) {
    const messageResult = result as { message: string };

    throw new Error(`${messageResult.message.replace('User does', 'You do')}`);
  }

  return { ok: true, url: (result as any).data.meta.paynl.links.paymentUrl };
}

export type TransferRequest = {
  recipientId: string;
  cents: number;
};

export async function transferMoney(
  endpoint: string,
  authorization: string,
  { recipientId, cents }: TransferRequest,
  signal?: AbortSignal,
  debug?: boolean
): Promise<{ message?: string; ok: boolean }> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/user/payment/internal/create/${recipientId}/${cents}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      method: 'GET',
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
      disableText: true,
    }
  );

  if ('error' in result) {
    const errorResult = result as { error: string; message: string };

    throw new Error(
      `${errorResult.error}: ${errorResult.message.replace(
        'User does',
        'You do'
      )}`
    );
  }

  return { ok: true };
}
