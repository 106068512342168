import { __rest } from "tslib";
import React from 'react';
import { LocalLocationListItem } from './LocalLocationListItem';
import { RemoteLocationListItem } from './RemoteLocationListItem';
export function LocationListItem(_a) {
    var { source } = _a, other = __rest(_a, ["source"]);
    if (typeof source !== 'string') {
        return React.createElement(LocalLocationListItem, Object.assign({}, source, other));
    }
    return React.createElement(RemoteLocationListItem, Object.assign({ id: source }, other));
}
