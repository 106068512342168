import { __rest } from "tslib";
import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Avatar, List } from 'react-native-paper';
import { eventTagIconFor } from './EventTag';
import { cleanTag } from './utils';
export function EventIcon(_a) {
    var { illustration, loading, tags, fallback, compact } = _a, others = __rest(_a, ["illustration", "loading", "tags", "fallback", "compact"]);
    if (loading) {
        return React.createElement(List.Icon, Object.assign({}, others, { icon: () => React.createElement(ActivityIndicator, null) }));
    }
    if (!illustration) {
        return (React.createElement(List.Icon, Object.assign({}, others, { icon: fallback ? firstTagIcon(tags) : 'calendar' })));
    }
    return (React.createElement(Avatar.Image, Object.assign({}, others, { size: 40, style: [styles.avatar, compact && styles.compactImage], source: { uri: illustration } })));
}
function firstTagIcon(tags) {
    if (!tags) {
        return 'calendar';
    }
    for (let i = 0; i < tags.length; i++) {
        const icon = eventTagIconFor(cleanTag(tags[i]));
        if (icon) {
            return icon;
        }
    }
    return 'calendar';
}
const styles = StyleSheet.create({
    avatar: {
        marginRight: 10,
        marginTop: 14,
        marginBottom: 6,
        marginLeft: 6,
    },
    compactImage: { marginTop: 8 },
});
