import { useTranslationAction } from '@introcloud/blocks/dist/TextBlock';
import I18n from 'i18n-js';
export function useTranslation(translatable, value, fallback) {
    var _a;
    // TODO replace with simpler hook
    useTranslationAction();
    if (!translatable) {
        return value || fallback || undefined;
    }
    return (_a = translatable[I18n.currentLocale()]) !== null && _a !== void 0 ? _a : (value || fallback);
}
