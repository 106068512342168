"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimestamp = exports.getCurrentTime = exports.setTime = exports.setTimeshift = void 0;
var react_1 = require("react");
var timeshift = 0;
function setTimeshift(shift) {
    timeshift = shift;
}
exports.setTimeshift = setTimeshift;
function setTime(time) {
    return setTimeshift(new Date(time).getTime() - new Date().getTime());
}
exports.setTime = setTime;
function getCurrentTime() {
    return new Date().getTime() + timeshift;
}
exports.getCurrentTime = getCurrentTime;
function useTimestamp(_a) {
    var every = _a.every;
    var _b = react_1.useState(function () { return getCurrentTime(); }), timestamp = _b[0], setTimestamp = _b[1];
    react_1.useEffect(function () {
        var active = true;
        var timeout = setTimeout(function () { return active && setTimestamp(function () { return getCurrentTime(); }); }, every);
        return function () {
            active = false;
            clearTimeout(timeout);
        };
    }, [timestamp]);
    return timestamp;
}
exports.useTimestamp = useTimestamp;
